/*Theme Colors*/
/*bootstrap Color*/
/*Light colors*/
/*Normal Color*/
/*Extra Variable*/
/*Gradiant*/
.card-body {
    padding: 20px; }
  
  /*******************
  shop listing
  *******************/
  .shop-listing .shop-hover {
    position: relative; }
    .shop-listing .shop-hover .card-img-overlay {
      display: none;
      background: rgba(255, 255, 255, 0.5);
      justify-content: center; }
    .shop-listing .shop-hover:hover .card-img-overlay {
      display: flex; }
    .shop-listing .shop-hover .label {
      padding: 5px 10px;
      position: absolute;
      top: 10px;
      right: 10px; }
  
  /*******************
  shop table
  *******************/
  .shop-table td {
    padding: 30px 0; }