/*Theme Colors*/
/*bootstrap Color*/
/*Light colors*/
/*Normal Color*/
/*Extra Variable*/
/*Gradiant*/
.card-body {
    padding: 20px; }
  
  /*******************
  Portfolio 1
  *******************/
  .img-ho {
    overflow: hidden; }
    .img-ho img {
      transition: 0.1s ease-in; }
      .img-ho img:hover {
        transform: scale(1.1); }
  
  /*******************
  Portfolio2
  *******************/
  .filterby a {
    padding: 10px;
    font-weight: 400;
    color: #8d97ad; }
    .filterby a:hover {
      color: #316ce8; }
    .filterby a:first-child {
      padding-left: 0px; }
  
  .hidden {
    display: none; }
  
  @media (max-width: 767px) {
    .filterby a {
      padding: 10px 5px; } }
  
  .portfolio-box {
    margin-top: 40px; }
    .portfolio-box .filter .overlay-box {
      margin-bottom: 30px;
      position: relative; }
      .portfolio-box .filter .overlay-box .img-ho {
        overflow: hidden;
        display: block; }
        .portfolio-box .filter .overlay-box .img-ho img {
          transition: 0.1s ease-in; }
        .portfolio-box .filter .overlay-box .img-ho:hover img {
          transform: scale(1.1); }
      .portfolio-box .filter .overlay-box .port-text {
        transition: 0.3s; }
        .portfolio-box .filter .overlay-box .port-text > div {
          padding: 20px 0 10px 0; }
        .portfolio-box .filter .overlay-box .port-text h5 {
          font-size: 18px;
          font-weight: 500;
          margin-bottom: 0px; }
        .portfolio-box .filter .overlay-box .port-text span {
          color: #8d97ad;
          font-size: 14px; }
  
  .like-dislike {
    padding: 0px !important; }
    .like-dislike .text-active {
      display: none; }
    .like-dislike.active .text {
      display: none; }
    .like-dislike.active .text-active {
      display: inline-block; }
  
  /*******************
  Portfolio4
  *******************/
  .portfolio4 .portfolio-box4 {
    margin-top: 60px; }
    .portfolio4 .portfolio-box4 .filter .overlay-box {
      margin-bottom: 30px;
      position: relative; }
      .portfolio4 .portfolio-box4 .filter .overlay-box .overlay {
        position: absolute;
        background: rgba(29, 200, 205, 0.9);
        transition: 0.3s;
        height: 0px;
        width: 100%;
        display: none;
        padding-top: 30%; }
        .portfolio4 .portfolio-box4 .filter .overlay-box .overlay .port-text {
          transition: 0.3s;
          position: absolute;
          top: 0px;
          width: 0px;
          text-align: center; }
          .portfolio4 .portfolio-box4 .filter .overlay-box .overlay .port-text h5 {
            color: #ffffff;
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 0px; }
          .portfolio4 .portfolio-box4 .filter .overlay-box .overlay .port-text span {
            color: #ffffff;
            font-size: 14px; }
      .portfolio4 .portfolio-box4 .filter .overlay-box:hover .overlay {
        display: block;
        top: 0px;
        height: 100%; }
        .portfolio4 .portfolio-box4 .filter .overlay-box:hover .overlay .port-text {
          top: 43%;
          width: 100%; }
  
  /*******************
  projects
  *******************/
  .img-ho {
    overflow: hidden; }
    .img-ho img {
      transition: 0.1s ease-in; }
      .img-ho img:hover {
        transform: scale(1.1); }