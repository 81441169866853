/*Theme Colors*/
/*bootstrap Color*/
/*Light colors*/
/*Normal Color*/
/*Extra Variable*/
/*Gradiant*/
.card-body {
    padding: 20px; }
  
  /*******************
  Feature 1
  *******************/
  .wrap-feature1-box .card-body {
    padding: 40px; }
  
  /*******************
  Feature 2
  *******************/
  .wrap-feature2-box .card-body {
    padding: 40px; }
  
  /*******************
  Feature 3
  *******************/
  .wrap-feature3-box .card-body {
    padding: 40px; }
    .wrap-feature3-box .card-body .icon-space {
      padding: 0px 40px 20px 0px; }
  
  /*******************
  Feature 4
  *******************/
  .wrap-feature4-box .icon-round {
    font-size: 45px;
    width: 80px;
    color: #316ce8;
    line-height: 80px;
    text-align: center;
    display: inline-block;
    margin: 20px 0 30px;
    border-radius: 100%; }
  
  /*******************
  Feature 5
  *******************/
  .wrap-feature5-box .card-body {
    padding: 30px; }
    .wrap-feature5-box .card-body .icon-space {
      padding: 0px 30px 20px 0px;
      font-size: 45px;
      margin: 0px; }
    .wrap-feature5-box .card-body p {
      margin-bottom: 0px; }
  
  /*******************
  Feature 6
  *******************/
  .wrap-feature6-box {
    transition: 0.2s ease-in; }
    .wrap-feature6-box .card-body {
      padding: 30px; }
      .wrap-feature6-box .card-body .linking {
        color: #ffffff; }
        .wrap-feature6-box .card-body .linking:hover {
          color: #263238; }
      .wrap-feature6-box .card-body p {
        opacity: 0.8; }
    .wrap-feature6-box:hover {
      transform: scale(1.1); }
  
  /*******************
  Feature 7
  *******************/
  .wrap-feature7-box {
    margin-bottom: 30px; }
  
  /*******************
  Feature 8
  *******************/
  .feature8 .list-block li {
    font-weight: 400;
    color: #263238; }
  
  /*******************
  Feature 9
  *******************/
  .wrap-feature9-box .card-body {
    padding: 40px 40px 0px; }
    .wrap-feature9-box .card-body .icon-space {
      padding: 0px 40px 20px 0px; }
  
  /*******************
  Feature 10
  *******************/
  .wrap-feature10-box {
    padding: 100px 20px;
    width: 100%;
    max-width: 450px;
    margin: 0 auto;
    color: #ffffff; }
    .wrap-feature10-box p {
      margin: 30px 0 40px; }
    .wrap-feature10-box .box-title {
      color: #ffffff; }

/*******************
Feature 11
*******************/
.wrap-feature11-box {
    margin-top: 60px; }
    .wrap-feature11-box .card-body {
      padding: 40px; }
    .wrap-feature11-box .icon-space {
      margin: -80px 0 20px; }
      .wrap-feature11-box .icon-space .icon-round {
        font-size: 45px;
        color: #ffffff; }
  
  /*******************
  Feature 12
  *******************/
  .wrap-feature-12 {
    position: relative; }
    .wrap-feature-12 [class*="col-md-12"] {
      margin-bottom: 30px;
      transition: 0.2s ease-in; }
      .wrap-feature-12 [class*="col-md-12"]:hover {
        transform: scale(1.1); }
    .wrap-feature-12 .uneven-box {
      margin-top: 100px; }
  
  /*******************
  Feature 16
  *******************/
  .wrap-feature-16 [class*="col-"] {
    padding: 80px 35px; }
  
  .wrap-feature-16 .with-bg {
    width: 100%;
    min-height: 200px;
    background-size: cover;
    background-position: center center; }
  
  .wrap-feature-16 .with-text {
    max-width: 500px;
    margin-left: 80px; }
  
  /*******************
  Feature 17
  *******************/
  .wrap-feature-17-box.card-group .card {
    margin-bottom: 0px; }
  
  /*******************
  Feature 18
  *******************/
  .wrap-feature-18 {
    margin-top: 60px; }
    .wrap-feature-18 [class*="col-lg-6"] .card {
      overflow: hidden; }
    .wrap-feature-18 .icon-position {
      position: relative;
      background-size: cover;
      background-position: center center;
      min-height: 200px; }
      .wrap-feature-18 .icon-position .icon-round {
        position: absolute;
        right: -38px;
        top: 20px; }
  
  /*******************
  Feature 20
  *******************/
  .wrap-feature-20 {
    margin-top: 60px; }
    .wrap-feature-20 [class*="col-lg-6"] .card {
      overflow: hidden; }
    .wrap-feature-20 .linking {
      width: 100%;
      display: block;
      padding: 35px 0; }
      
/*******************
Feature 21
*******************/
.wrap-feature21-box {
    background-size: cover;
    background-attachment: fixed;
    background-position: left center; }
  
  /*******************
  Feature 22
  *******************/
  .wrap-feature-22 {
    margin-top: 60px; }
    .wrap-feature-22 .text-box {
      padding: 0 30px; }
      .wrap-feature-22 .text-box h3 {
        margin: 10px 0 25px 0; }
  
  /*******************
  Feature 23
  *******************/
  .wrap-feature23-box {
    background-size: cover;
    background-attachment: fixed;
    background-position: left center; }
    .wrap-feature23-box .text-box {
      padding: 30px 20px; }
  
  /*******************
  Feature 24
  *******************/
  .wrap-feature-24 {
    margin-top: 60px; }
    .wrap-feature-24 .card {
      overflow: hidden;
      transition: 0.3s ease-out;
      -webkit-transition: 0.3s ease-out; }
      .wrap-feature-24 .card:hover {
        transform: translateY(-10px);
        -webkit-transform: translateY(-10px); }
    .wrap-feature-24 .service-24 {
      text-align: center;
      padding: 40px 0;
      display: block; }
      .wrap-feature-24 .service-24 i {
        background: #2cdd9b;
        background: -webkit-linear-gradient(legacy-direction(to right), #2cdd9b 0%, #1dc8cc 100%);
        background: linear-gradient(to right, #2cdd9b 0%, #1dc8cc 100%);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        text-fill-color: transparent;
        font-size: 50px; }
      .wrap-feature-24 .service-24 .ser-title {
        margin: 10px 0 5px;
        font-weight: 500; }
      .wrap-feature-24 .service-24:hover, .wrap-feature-24 .service-24:focus {
        background: #2cdd9b;
        background: -webkit-linear-gradient(legacy-direction(to right), #2cdd9b 0%, #1dc8cc 100%);
        background: linear-gradient(to right, #2cdd9b 0%, #1dc8cc 100%); }
        .wrap-feature-24 .service-24:hover i,
        .wrap-feature-24 .service-24:hover .ser-title, .wrap-feature-24 .service-24:focus i,
        .wrap-feature-24 .service-24:focus .ser-title {
          color: #ffffff;
          text-fill-color: #ffffff;
          -webkit-text-fill-color: #ffffff; }
        .wrap-feature-24 .service-24:hover i, .wrap-feature-24 .service-24:focus i {
          margin-bottom: 5px; }
  
  /*******************
  Feature 26
  *******************/
  .wrap-feature-26 .max-box {
    max-width: 580px; }
  
  .wrap-feature-28 .side-content {
    max-width: 580px; }
  
  /*******************
  Feature 29
  *******************/
  .wrap-feature-29 {
    margin-top: 60px; }
    .wrap-feature-29 .title,
    .wrap-feature-29 .subtitle {
      color: #ffffff; }
    .wrap-feature-29 .col-lg-4 {
      margin-top: 30px; }
  
  /*******************
  Feature 30
  *******************/
  .feature30 {
    position: relative; }
  
  .wrap-feature30-box {
    position: absolute;
    top: 20%;
    right: 0px; }
    
/*******************
Feature 31
*******************/
.wrap-feature31-box {
    overflow: hidden;
    position: relative; }
    .wrap-feature31-box .half-radius {
      border-radius: 62% / 100%;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0; }
    .wrap-feature31-box .right-image {
      position: absolute;
      right: 0px;
      text-align: center;
      bottom: -60px; }
  
  /*******************
  Feature 33
  *******************/
  .wrap-feature-33 .play-btn {
    position: relative; }
    .wrap-feature-33 .play-btn span.display-1 {
      font-weight: 500;
      opacity: 0.15; }
    .wrap-feature-33 .play-btn a {
      position: absolute;
      left: 40px;
      top: 20px;
      color: #263238;
      font-weight: 500; }
      .wrap-feature-33 .play-btn a span {
        box-shadow: 2px 8px 20px rgba(255, 77, 126, 0.5); }
      .wrap-feature-33 .play-btn a:hover {
        color: #ff4d7e; }
  
  /*******************
  Feature 34
  *******************/
  .wrap-feature34-box {
    overflow: hidden;
    position: relative; }
    .wrap-feature34-box .left-image {
      position: absolute;
      left: -15px;
      text-align: left;
      top: 0px; }
  
  /*******************
  Feature 35
  *******************/
  .wrap-feature35-box .feature-35-owl .item {
    position: relative; }
    .wrap-feature35-box .feature-35-owl .item h4 {
      position: absolute;
      bottom: 20px;
      left: 30px;
      color: #ffffff;
      text-transform: uppercase; }
  
  /*******************
  Feature 36
  *******************/
  .wrap-feature36-box {
    background-position: right top;
    background-repeat: no-repeat; }
  
  /*******************
  Feature 38
  *******************/
  .wrap-feature38-box .po-absolute {
    right: 0px;
    z-index: 1; }
  
  /*******************
  Feature 39
  *******************/
  .wrap-feature39-box .col-lg-6 > img {
    margin-left: -40px; }
  
  /*******************
  Feature 40
  *******************/
  .wrap-feature40-box {
    background-position: left top, top right;
    background-repeat: no-repeat, no-repeat; }
    
/*******************
Feature 41
*******************/
.wrap-feature41-box .f41-tab {
    display: block; }
    .wrap-feature41-box .f41-tab li a {
      display: flex;
      margin-bottom: 20px;
      padding: 25px; }
      .wrap-feature41-box .f41-tab li a .t-icon {
        margin-right: 25px;
        color: #8d97ad; }
      .wrap-feature41-box .f41-tab li a h5 {
        font-weight: 500; }
      .wrap-feature41-box .f41-tab li a h6 {
        font-size: 14px;
        margin-bottom: 0px; }
      .wrap-feature41-box .f41-tab li a.active, .wrap-feature41-box .f41-tab li a:hover {
        background: #ffffff;
        box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1); }
        .wrap-feature41-box .f41-tab li a.active .t-icon, .wrap-feature41-box .f41-tab li a:hover .t-icon {
          background: #188ef4;
          background: -webkit-linear-gradient(legacy-direction(to right), #188ef4 0%, #316ce8 100%);
          background: linear-gradient(to right, #188ef4 0%, #316ce8 100%);
          -webkit-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: transparent;
          text-fill-color: transparent; }
  
  @media (max-width: 1023px) {
    .wrap-feature41-box .f41-tab li a {
      padding: 20px; }
      .wrap-feature41-box .f41-tab li a .display-5 {
        font-size: 2.5rem; }
      .wrap-feature41-box .f41-tab li a h5 {
        font-size: 16px; } }
  
  /*******************
  Feature 42
  *******************/
  .feature42 {
    background-repeat: no-repeat;
    background-size: cover; }
    .feature42 .wrap-feature42-box {
      padding: 15% 0; }
  
  /*******************
  Feature 43
  *******************/
  .wrap-feature43-box .col-md-6 {
    margin-bottom: 50px; }
  
  /*******************
  Feature 45
  *******************/
  .wrap-feature45-box {
    margin-top: 60px; }
    .wrap-feature45-box .f45-tab {
      display: table;
      width: 100%; }
      .wrap-feature45-box .f45-tab > a {
        display: table-cell;
        border: 1px solid rgba(120, 130, 140, 0.13);
        text-align: center;
        padding: 15px 10px;
        background: #f4f8fa;
        color: #263238; }
        .wrap-feature45-box .f45-tab > a.active {
          border-bottom-color: transparent;
          background: #ffffff;
          font-weight: 400; }
    .wrap-feature45-box .tab-pane {
      padding: 60px 0; }
  
  /*******************
  Feature 46
  *******************/
  .feature46 {
    background-repeat: no-repeat;
    background-position: top right; }
  
  /*******************
  Feature 47
  *******************/
  .wrap-feature47-box .f-47-line {
    line-height: 40px; }
  
  /*******************
  Feature 48
  *******************/
  .feature48 {
    background-repeat: no-repeat;
    background-position: top left;
    background-size: cover; }
    .feature48 .wrap-feature48-box {
      padding: 10% 0; }
  
  /*******************
  Feature 49
  *******************/    