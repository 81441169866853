.cursor_Pointer {
	cursor: pointer;
}

.border_Width-1 {
	border-width: 0.5rem !important;
}

.custfooter {
	padding: 10px 0;
	padding-top: 10px;
	padding-right: 0px;
	padding-bottom: 10px;
	padding-left: 0px;
	text-align: center;
}

/* Navs */

@keyframes bounce {
	from,
	20%,
	53%,
	80%,
	to {
		-webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	40%,
	43% {
		-webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
		animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
		-webkit-transform: translate3d(0, -30px, 0);
		transform: translate3d(0, -30px, 0);
	}

	70% {
		-webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
		animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
		-webkit-transform: translate3d(0, -15px, 0);
		transform: translate3d(0, -15px, 0);
	}

	90% {
		-webkit-transform: translate3d(0, -4px, 0);
		transform: translate3d(0, -4px, 0);
	}
}
@-webkit-keyframes flash {
	from,
	to {
		opacity: 1;
	}
}
@keyframes flash {
	from,
	50%,
	to {
		opacity: 1;
	}

	25%,
	75% {
		opacity: 0;
	}
}
@-webkit-keyframes pulse {
	from {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}

	to {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
}
@keyframes pulse {
	from {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}

	50% {
		-webkit-transform: scale3d(1.05, 1.05, 1.05);
		transform: scale3d(1.05, 1.05, 1.05);
	}

	to {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
}
@-webkit-keyframes rubberBand {
	from {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}

	to {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
}
@keyframes rubberBand {
	from {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}

	30% {
		-webkit-transform: scale3d(1.25, 0.75, 1);
		transform: scale3d(1.25, 0.75, 1);
	}

	40% {
		-webkit-transform: scale3d(0.75, 1.25, 1);
		transform: scale3d(0.75, 1.25, 1);
	}

	50% {
		-webkit-transform: scale3d(1.15, 0.85, 1);
		transform: scale3d(1.15, 0.85, 1);
	}

	65% {
		-webkit-transform: scale3d(0.95, 1.05, 1);
		transform: scale3d(0.95, 1.05, 1);
	}

	75% {
		-webkit-transform: scale3d(1.05, 0.95, 1);
		transform: scale3d(1.05, 0.95, 1);
	}

	to {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
}
@-webkit-keyframes shake {
	from,
	to {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}
@keyframes shake {
	from,
	to {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	10%,
	30%,
	50%,
	70%,
	90% {
		-webkit-transform: translate3d(-10px, 0, 0);
		transform: translate3d(-10px, 0, 0);
	}

	20%,
	40%,
	60%,
	80% {
		-webkit-transform: translate3d(10px, 0, 0);
		transform: translate3d(10px, 0, 0);
	}
}
@-webkit-keyframes swing {
	to {
		-webkit-transform: rotate3d(0, 0, 1, 0deg);
		transform: rotate3d(0, 0, 1, 0deg);
	}
}
@keyframes swing {
	20% {
		-webkit-transform: rotate3d(0, 0, 1, 15deg);
		transform: rotate3d(0, 0, 1, 15deg);
	}

	40% {
		-webkit-transform: rotate3d(0, 0, 1, -10deg);
		transform: rotate3d(0, 0, 1, -10deg);
	}

	60% {
		-webkit-transform: rotate3d(0, 0, 1, 5deg);
		transform: rotate3d(0, 0, 1, 5deg);
	}

	80% {
		-webkit-transform: rotate3d(0, 0, 1, -5deg);
		transform: rotate3d(0, 0, 1, -5deg);
	}

	to {
		-webkit-transform: rotate3d(0, 0, 1, 0deg);
		transform: rotate3d(0, 0, 1, 0deg);
	}
}
.swing {
	-webkit-transform-origin: top center;
	transform-origin: top center;
	-webkit-animation-name: swing;
	animation-name: swing;
}
@-webkit-keyframes tada {
	from {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}

	to {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
}
@keyframes tada {
	from {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}

	10%,
	20% {
		-webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
		transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
	}

	30%,
	50%,
	70%,
	90% {
		-webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
		transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
	}

	40%,
	60%,
	80% {
		-webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
		transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
	}

	to {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
}
@-webkit-keyframes wobble {
	from {
		-webkit-transform: none;
		transform: none;
	}

	to {
		-webkit-transform: none;
		transform: none;
	}
}
@keyframes wobble {
	from {
		-webkit-transform: none;
		transform: none;
	}

	15% {
		-webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
		transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
	}

	30% {
		-webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
		transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
	}

	45% {
		-webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
		transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
	}

	60% {
		-webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
		transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
	}

	75% {
		-webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
		transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
	}

	to {
		-webkit-transform: none;
		transform: none;
	}
}
@-webkit-keyframes jello {
	from,
	to {
		-webkit-transform: none;
		transform: none;
	}
}
@keyframes jello {
	from,
	11.1%,
	to {
		-webkit-transform: none;
		transform: none;
	}

	22.2% {
		-webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
		transform: skewX(-12.5deg) skewY(-12.5deg);
	}

	33.3% {
		-webkit-transform: skewX(6.25deg) skewY(6.25deg);
		transform: skewX(6.25deg) skewY(6.25deg);
	}

	44.4% {
		-webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
		transform: skewX(-3.125deg) skewY(-3.125deg);
	}

	55.5% {
		-webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
		transform: skewX(1.5625deg) skewY(1.5625deg);
	}

	66.6% {
		-webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
		transform: skewX(-0.78125deg) skewY(-0.78125deg);
	}

	77.7% {
		-webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
		transform: skewX(0.390625deg) skewY(0.390625deg);
	}

	88.8% {
		-webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
		transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
	}
}
@-webkit-keyframes bounceIn {
	from,
	to {
		-webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}

	to {
		opacity: 1;
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
}
@keyframes bounceIn {
	from,
	20%,
	40%,
	60%,
	80%,
	to {
		-webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}

	0% {
		opacity: 0;
		-webkit-transform: scale3d(0.3, 0.3, 0.3);
		transform: scale3d(0.3, 0.3, 0.3);
	}

	20% {
		-webkit-transform: scale3d(1.1, 1.1, 1.1);
		transform: scale3d(1.1, 1.1, 1.1);
	}

	40% {
		-webkit-transform: scale3d(0.9, 0.9, 0.9);
		transform: scale3d(0.9, 0.9, 0.9);
	}

	60% {
		opacity: 1;
		-webkit-transform: scale3d(1.03, 1.03, 1.03);
		transform: scale3d(1.03, 1.03, 1.03);
	}

	80% {
		-webkit-transform: scale3d(0.97, 0.97, 0.97);
		transform: scale3d(0.97, 0.97, 0.97);
	}

	to {
		opacity: 1;
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
}
@-webkit-keyframes bounceInDown {
	from,
	to {
		-webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}

	to {
		-webkit-transform: none;
		transform: none;
	}
}
@keyframes bounceInDown {
	from,
	60%,
	75%,
	90%,
	to {
		-webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}

	0% {
		opacity: 0;
		-webkit-transform: translate3d(0, -3000px, 0);
		transform: translate3d(0, -3000px, 0);
	}

	60% {
		opacity: 1;
		-webkit-transform: translate3d(0, 25px, 0);
		transform: translate3d(0, 25px, 0);
	}

	75% {
		-webkit-transform: translate3d(0, -10px, 0);
		transform: translate3d(0, -10px, 0);
	}

	90% {
		-webkit-transform: translate3d(0, 5px, 0);
		transform: translate3d(0, 5px, 0);
	}

	to {
		-webkit-transform: none;
		transform: none;
	}
}
@-webkit-keyframes bounceInLeft {
	from,
	to {
		-webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}

	to {
		-webkit-transform: none;
		transform: none;
	}
}
@keyframes bounceInLeft {
	from,
	60%,
	75%,
	90%,
	to {
		-webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}

	0% {
		opacity: 0;
		-webkit-transform: translate3d(-3000px, 0, 0);
		transform: translate3d(-3000px, 0, 0);
	}

	60% {
		opacity: 1;
		-webkit-transform: translate3d(25px, 0, 0);
		transform: translate3d(25px, 0, 0);
	}

	75% {
		-webkit-transform: translate3d(-10px, 0, 0);
		transform: translate3d(-10px, 0, 0);
	}

	90% {
		-webkit-transform: translate3d(5px, 0, 0);
		transform: translate3d(5px, 0, 0);
	}

	to {
		-webkit-transform: none;
		transform: none;
	}
}
@-webkit-keyframes bounceInRight {
	from,
	to {
		-webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}

	from {
		opacity: 0;
		-webkit-transform: translate3d(3000px, 0, 0);
		transform: translate3d(3000px, 0, 0);
	}

	to {
		-webkit-transform: none;
		transform: none;
	}
}
@keyframes bounceInRight {
	from,
	60%,
	75%,
	90%,
	to {
		-webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}

	from {
		opacity: 0;
		-webkit-transform: translate3d(3000px, 0, 0);
		transform: translate3d(3000px, 0, 0);
	}

	60% {
		opacity: 1;
		-webkit-transform: translate3d(-25px, 0, 0);
		transform: translate3d(-25px, 0, 0);
	}

	75% {
		-webkit-transform: translate3d(10px, 0, 0);
		transform: translate3d(10px, 0, 0);
	}

	90% {
		-webkit-transform: translate3d(-5px, 0, 0);
		transform: translate3d(-5px, 0, 0);
	}

	to {
		-webkit-transform: none;
		transform: none;
	}
}
@-webkit-keyframes bounceInUp {
	from,
	to {
		-webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}

	from {
		opacity: 0;
		-webkit-transform: translate3d(0, 3000px, 0);
		transform: translate3d(0, 3000px, 0);
	}

	to {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}
@keyframes bounceInUp {
	from,
	60%,
	75%,
	90%,
	to {
		-webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}

	from {
		opacity: 0;
		-webkit-transform: translate3d(0, 3000px, 0);
		transform: translate3d(0, 3000px, 0);
	}

	60% {
		opacity: 1;
		-webkit-transform: translate3d(0, -20px, 0);
		transform: translate3d(0, -20px, 0);
	}

	75% {
		-webkit-transform: translate3d(0, 10px, 0);
		transform: translate3d(0, 10px, 0);
	}

	90% {
		-webkit-transform: translate3d(0, -5px, 0);
		transform: translate3d(0, -5px, 0);
	}

	to {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}
@-webkit-keyframes bounceOut {
	to {
		opacity: 0;
		-webkit-transform: scale3d(0.3, 0.3, 0.3);
		transform: scale3d(0.3, 0.3, 0.3);
	}
}
@keyframes bounceOut {
	20% {
		-webkit-transform: scale3d(0.9, 0.9, 0.9);
		transform: scale3d(0.9, 0.9, 0.9);
	}

	50%,
	55% {
		opacity: 1;
		-webkit-transform: scale3d(1.1, 1.1, 1.1);
		transform: scale3d(1.1, 1.1, 1.1);
	}

	to {
		opacity: 0;
		-webkit-transform: scale3d(0.3, 0.3, 0.3);
		transform: scale3d(0.3, 0.3, 0.3);
	}
}
@-webkit-keyframes bounceOutDown {
	to {
		opacity: 0;
		-webkit-transform: translate3d(0, 2000px, 0);
		transform: translate3d(0, 2000px, 0);
	}
}
@keyframes bounceOutDown {
	20% {
		-webkit-transform: translate3d(0, 10px, 0);
		transform: translate3d(0, 10px, 0);
	}

	40%,
	45% {
		opacity: 1;
		-webkit-transform: translate3d(0, -20px, 0);
		transform: translate3d(0, -20px, 0);
	}

	to {
		opacity: 0;
		-webkit-transform: translate3d(0, 2000px, 0);
		transform: translate3d(0, 2000px, 0);
	}
}
@-webkit-keyframes bounceOutLeft {
	to {
		opacity: 0;
		-webkit-transform: translate3d(-2000px, 0, 0);
		transform: translate3d(-2000px, 0, 0);
	}
}
@keyframes bounceOutLeft {
	20% {
		opacity: 1;
		-webkit-transform: translate3d(20px, 0, 0);
		transform: translate3d(20px, 0, 0);
	}

	to {
		opacity: 0;
		-webkit-transform: translate3d(-2000px, 0, 0);
		transform: translate3d(-2000px, 0, 0);
	}
}
@-webkit-keyframes bounceOutRight {
	to {
		opacity: 0;
		-webkit-transform: translate3d(2000px, 0, 0);
		transform: translate3d(2000px, 0, 0);
	}
}
@keyframes bounceOutRight {
	20% {
		opacity: 1;
		-webkit-transform: translate3d(-20px, 0, 0);
		transform: translate3d(-20px, 0, 0);
	}

	to {
		opacity: 0;
		-webkit-transform: translate3d(2000px, 0, 0);
		transform: translate3d(2000px, 0, 0);
	}
}
@-webkit-keyframes bounceOutUp {
	to {
		opacity: 0;
		-webkit-transform: translate3d(0, -2000px, 0);
		transform: translate3d(0, -2000px, 0);
	}
}
@keyframes bounceOutUp {
	20% {
		-webkit-transform: translate3d(0, -10px, 0);
		transform: translate3d(0, -10px, 0);
	}

	40%,
	45% {
		opacity: 1;
		-webkit-transform: translate3d(0, 20px, 0);
		transform: translate3d(0, 20px, 0);
	}

	to {
		opacity: 0;
		-webkit-transform: translate3d(0, -2000px, 0);
		transform: translate3d(0, -2000px, 0);
	}
}
@-webkit-keyframes fadeIn {
	from {
		opacity: 0;
		visibility: hidden;
		-ms-transform: scale(0.95);
		-webkit-transform: scale(0.95);
		transform: scale(0.95);
	}

	to {
		opacity: 1;
		visibility: visible;
		-ms-transform: scale(1);
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}
@keyframes fadeIn {
	from {
		opacity: 0;
		visibility: hidden;
		-ms-transform: scale(0.95);
		-webkit-transform: scale(0.95);
		transform: scale(0.95);
	}

	to {
		opacity: 1;
		visibility: visible;
		-ms-transform: scale(1);
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}
.fadeIn {
	-webkit-animation-name: fadeIn;
	animation-name: fadeIn;
}
@-webkit-keyframes fadeInDown {
	from {
		opacity: 0;
		visibility: hidden;
		/*-webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);*/
		-webkit-transform: translate3d(0, -50px, 0);
		transform: translate3d(0, -50px, 0);
	}

	to {
		opacity: 1;
		visibility: visible;
		-webkit-transform: none;
		transform: none;
	}
}
@keyframes fadeInDown {
	from {
		opacity: 0;
		visibility: hidden;
		/*-webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);*/
		-webkit-transform: translate3d(0, -50px, 0);
		transform: translate3d(0, -50px, 0);
	}

	to {
		opacity: 1;
		visibility: visible;
		-webkit-transform: none;
		transform: none;
	}
}
@-webkit-keyframes fadeInDownBig {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, -2000px, 0);
		transform: translate3d(0, -2000px, 0);
	}

	to {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}
@keyframes fadeInDownBig {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, -2000px, 0);
		transform: translate3d(0, -2000px, 0);
	}

	to {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}
@-webkit-keyframes fadeInLeft {
	from {
		opacity: 0;
		visibility: hidden;
		/*-webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);*/
		-webkit-transform: translate3d(-50px, 0, 0);
		transform: translate3d(-50px, 0, 0);
	}

	to {
		opacity: 1;
		visibility: visible;
		-webkit-transform: none;
		transform: none;
	}
}
@keyframes fadeInLeft {
	from {
		opacity: 0;
		visibility: hidden;
		/*-webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);*/
		-webkit-transform: translate3d(-50px, 0, 0);
		transform: translate3d(-50px, 0, 0);
	}

	to {
		opacity: 1;
		visibility: visible;
		-webkit-transform: none;
		transform: none;
	}
}
.fadeInLeft {
	-webkit-animation-name: fadeInLeft;
	animation-name: fadeInLeft;
}
@-webkit-keyframes fadeInLeftBig {
	from {
		opacity: 0;
		-webkit-transform: translate3d(-2000px, 0, 0);
		transform: translate3d(-2000px, 0, 0);
	}

	to {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}
@keyframes fadeInLeftBig {
	from {
		opacity: 0;
		-webkit-transform: translate3d(-2000px, 0, 0);
		transform: translate3d(-2000px, 0, 0);
	}

	to {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}
@-webkit-keyframes fadeInRight {
	from {
		opacity: 0;
		visibility: hidden;
		/*-webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);*/
		-webkit-transform: translate3d(50px, 0, 0);
		transform: translate3d(50px, 0, 0);
	}

	to {
		opacity: 1;
		visibility: visible;
		-webkit-transform: none;
		transform: none;
	}
}
@keyframes fadeInRight {
	from {
		opacity: 0;
		visibility: hidden;
		/*-webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);*/
		-webkit-transform: translate3d(50px, 0, 0);
		transform: translate3d(50px, 0, 0);
	}

	to {
		opacity: 1;
		visibility: visible;
		-webkit-transform: none;
		transform: none;
	}
}
.fadeInRight {
	-webkit-animation-name: fadeInRight;
	animation-name: fadeInRight;
}
@-webkit-keyframes fadeInRightBig {
	from {
		opacity: 0;
		-webkit-transform: translate3d(2000px, 0, 0);
		transform: translate3d(2000px, 0, 0);
	}

	to {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}
@keyframes fadeInRightBig {
	from {
		opacity: 0;
		-webkit-transform: translate3d(2000px, 0, 0);
		transform: translate3d(2000px, 0, 0);
	}

	to {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}
@-webkit-keyframes fadeInUp {
	from {
		opacity: 0;
		visibility: hidden;
		/*-webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);*/
		-webkit-transform: translate3d(0, 40px, 0);
		transform: translate3d(0, 40px, 0);
	}

	to {
		opacity: 1;
		visibility: visible;
		-webkit-transform: none;
		transform: none;
	}
}
@keyframes fadeInUp {
	from {
		opacity: 0;
		visibility: hidden;
		/*-webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);*/
		-webkit-transform: translate3d(0, 40px, 0);
		transform: translate3d(0, 40px, 0);
	}

	to {
		visibility: visible;
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}
.fadeInUp {
	-webkit-animation-name: fadeInUp;
	animation-name: fadeInUp;
}
@-webkit-keyframes fadeInUpMenu {
	from {
		opacity: 0;
		visibility: hidden;
		/*-webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);*/
		-webkit-transform: translate3d(0, 20px, 0);
		transform: translate3d(0, 20px, 0);
	}

	to {
		opacity: 1;
		visibility: visible;
		-webkit-transform: none;
		transform: none;
	}
}
@keyframes fadeInUpMenu {
	from {
		opacity: 0;
		visibility: hidden;
		/*-webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);*/
		-webkit-transform: translate3d(0, 20px, 0);
		transform: translate3d(0, 20px, 0);
	}

	to {
		visibility: visible;
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}
@-webkit-keyframes fadeInUpBig {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, 2000px, 0);
		transform: translate3d(0, 2000px, 0);
	}

	to {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}
@keyframes fadeInUpBig {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, 2000px, 0);
		transform: translate3d(0, 2000px, 0);
	}

	to {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}
@-webkit-keyframes fadeOut {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
}
@keyframes fadeOut {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
}
.fadeOut {
	-webkit-animation-name: fadeOut;
	animation-name: fadeOut;
}
@-webkit-keyframes fadeOutDown {
	from {
		opacity: 1;
		visibility: visible;
	}

	to {
		opacity: 0;
		visibility: hidden;
		-webkit-transform: translate3d(0, 40px, 0);
		transform: translate3d(0, 40px, 0);
	}
}
@keyframes fadeOutDown {
	from {
		opacity: 1;
		visibility: visible;
	}

	to {
		opacity: 0;
		visibility: hidden;
		-webkit-transform: translate3d(0, 40px, 0);
		transform: translate3d(0, 40px, 0);
	}
}
@-webkit-keyframes fadeOutDownBig {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
		-webkit-transform: translate3d(0, 2000px, 0);
		transform: translate3d(0, 2000px, 0);
	}
}
@keyframes fadeOutDownBig {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
		-webkit-transform: translate3d(0, 2000px, 0);
		transform: translate3d(0, 2000px, 0);
	}
}
@-webkit-keyframes fadeOutLeft {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
		-webkit-transform: translate3d(-100%, 0, 0);
		transform: translate3d(-100%, 0, 0);
	}
}
@keyframes fadeOutLeft {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
		-webkit-transform: translate3d(-100%, 0, 0);
		transform: translate3d(-100%, 0, 0);
	}
}
@-webkit-keyframes fadeOutLeftBig {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
		-webkit-transform: translate3d(-2000px, 0, 0);
		transform: translate3d(-2000px, 0, 0);
	}
}
@keyframes fadeOutLeftBig {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
		-webkit-transform: translate3d(-2000px, 0, 0);
		transform: translate3d(-2000px, 0, 0);
	}
}
@-webkit-keyframes fadeOutRight {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
		-webkit-transform: translate3d(100%, 0, 0);
		transform: translate3d(100%, 0, 0);
	}
}
@keyframes fadeOutRight {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
		-webkit-transform: translate3d(100%, 0, 0);
		transform: translate3d(100%, 0, 0);
	}
}
@-webkit-keyframes fadeOutRightBig {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
		-webkit-transform: translate3d(2000px, 0, 0);
		transform: translate3d(2000px, 0, 0);
	}
}
@keyframes fadeOutRightBig {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
		-webkit-transform: translate3d(2000px, 0, 0);
		transform: translate3d(2000px, 0, 0);
	}
}
@-webkit-keyframes fadeOutUp {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
		-webkit-transform: translate3d(0, -100%, 0);
		transform: translate3d(0, -100%, 0);
	}
}
@keyframes fadeOutUp {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
		-webkit-transform: translate3d(0, -100%, 0);
		transform: translate3d(0, -100%, 0);
	}
}
@-webkit-keyframes fadeOutUpBig {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
		-webkit-transform: translate3d(0, -2000px, 0);
		transform: translate3d(0, -2000px, 0);
	}
}
@keyframes fadeOutUpBig {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
		-webkit-transform: translate3d(0, -2000px, 0);
		transform: translate3d(0, -2000px, 0);
	}
}
@-webkit-keyframes flip {
	from {
		-webkit-transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
		transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
	}

	to {
		-webkit-transform: perspective(400px);
		transform: perspective(400px);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}
}
@keyframes flip {
	from {
		-webkit-transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
		transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
	}

	40% {
		-webkit-transform: perspective(400px) translate3d(0, 0, 150px)
			rotate3d(0, 1, 0, -190deg);
		transform: perspective(400px) translate3d(0, 0, 150px)
			rotate3d(0, 1, 0, -190deg);
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
	}

	50% {
		-webkit-transform: perspective(400px) translate3d(0, 0, 150px)
			rotate3d(0, 1, 0, -170deg);
		transform: perspective(400px) translate3d(0, 0, 150px)
			rotate3d(0, 1, 0, -170deg);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}

	80% {
		-webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
		transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}

	to {
		-webkit-transform: perspective(400px);
		transform: perspective(400px);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}
}
.animated.flip {
	-webkit-backface-visibility: visible;
	backface-visibility: visible;
	-webkit-animation-name: flip;
	animation-name: flip;
}
@-webkit-keyframes flipInX {
	from {
		-webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
		transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
		opacity: 0;
	}

	to {
		-webkit-transform: perspective(400px);
		transform: perspective(400px);
	}
}
@keyframes flipInX {
	from {
		-webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
		transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
		opacity: 0;
	}

	40% {
		-webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
		transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}

	60% {
		-webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
		transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
		opacity: 1;
	}

	80% {
		-webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
		transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
	}

	to {
		-webkit-transform: perspective(400px);
		transform: perspective(400px);
	}
}
@-webkit-keyframes flipInY {
	from {
		-webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
		transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
		opacity: 0;
	}

	to {
		-webkit-transform: perspective(400px);
		transform: perspective(400px);
	}
}
@keyframes flipInY {
	from {
		-webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
		transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
		opacity: 0;
	}

	40% {
		-webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
		transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}

	60% {
		-webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
		transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
		opacity: 1;
	}

	80% {
		-webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
		transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
	}

	to {
		-webkit-transform: perspective(400px);
		transform: perspective(400px);
	}
}
@-webkit-keyframes flipOutX {
	from {
		-webkit-transform: perspective(400px);
		transform: perspective(400px);
	}

	to {
		-webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
		transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
		opacity: 0;
	}
}
@keyframes flipOutX {
	from {
		-webkit-transform: perspective(400px);
		transform: perspective(400px);
	}

	30% {
		-webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
		transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
		opacity: 1;
	}

	to {
		-webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
		transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
		opacity: 0;
	}
}
@-webkit-keyframes flipOutY {
	from {
		-webkit-transform: perspective(400px);
		transform: perspective(400px);
	}

	to {
		-webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
		transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
		opacity: 0;
	}
}
@keyframes flipOutY {
	from {
		-webkit-transform: perspective(400px);
		transform: perspective(400px);
	}

	30% {
		-webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
		transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
		opacity: 1;
	}

	to {
		-webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
		transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
		opacity: 0;
	}
}
@-webkit-keyframes lightSpeedIn {
	from {
		-webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
		transform: translate3d(100%, 0, 0) skewX(-30deg);
		opacity: 0;
	}

	to {
		-webkit-transform: none;
		transform: none;
		opacity: 1;
	}
}
@keyframes lightSpeedIn {
	from {
		-webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
		transform: translate3d(100%, 0, 0) skewX(-30deg);
		opacity: 0;
	}

	60% {
		-webkit-transform: skewX(20deg);
		transform: skewX(20deg);
		opacity: 1;
	}

	80% {
		-webkit-transform: skewX(-5deg);
		transform: skewX(-5deg);
		opacity: 1;
	}

	to {
		-webkit-transform: none;
		transform: none;
		opacity: 1;
	}
}
@-webkit-keyframes lightSpeedOut {
	from {
		opacity: 1;
	}

	to {
		-webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
		transform: translate3d(100%, 0, 0) skewX(30deg);
		opacity: 0;
	}
}
@keyframes lightSpeedOut {
	from {
		opacity: 1;
	}

	to {
		-webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
		transform: translate3d(100%, 0, 0) skewX(30deg);
		opacity: 0;
	}
}
@-webkit-keyframes rotateIn {
	from {
		-webkit-transform-origin: center;
		transform-origin: center;
		-webkit-transform: rotate3d(0, 0, 1, -200deg);
		transform: rotate3d(0, 0, 1, -200deg);
		opacity: 0;
	}

	to {
		-webkit-transform-origin: center;
		transform-origin: center;
		-webkit-transform: none;
		transform: none;
		opacity: 1;
	}
}
@keyframes rotateIn {
	from {
		-webkit-transform-origin: center;
		transform-origin: center;
		-webkit-transform: rotate3d(0, 0, 1, -200deg);
		transform: rotate3d(0, 0, 1, -200deg);
		opacity: 0;
	}

	to {
		-webkit-transform-origin: center;
		transform-origin: center;
		-webkit-transform: none;
		transform: none;
		opacity: 1;
	}
}
@-webkit-keyframes rotateInDownLeft {
	from {
		-webkit-transform-origin: left bottom;
		transform-origin: left bottom;
		-webkit-transform: rotate3d(0, 0, 1, -45deg);
		transform: rotate3d(0, 0, 1, -45deg);
		opacity: 0;
	}

	to {
		-webkit-transform-origin: left bottom;
		transform-origin: left bottom;
		-webkit-transform: none;
		transform: none;
		opacity: 1;
	}
}
@keyframes rotateInDownLeft {
	from {
		-webkit-transform-origin: left bottom;
		transform-origin: left bottom;
		-webkit-transform: rotate3d(0, 0, 1, -45deg);
		transform: rotate3d(0, 0, 1, -45deg);
		opacity: 0;
	}

	to {
		-webkit-transform-origin: left bottom;
		transform-origin: left bottom;
		-webkit-transform: none;
		transform: none;
		opacity: 1;
	}
}
@-webkit-keyframes rotateInDownRight {
	from {
		-webkit-transform-origin: right bottom;
		transform-origin: right bottom;
		-webkit-transform: rotate3d(0, 0, 1, 45deg);
		transform: rotate3d(0, 0, 1, 45deg);
		opacity: 0;
	}

	to {
		-webkit-transform-origin: right bottom;
		transform-origin: right bottom;
		-webkit-transform: none;
		transform: none;
		opacity: 1;
	}
}
@keyframes rotateInDownRight {
	from {
		-webkit-transform-origin: right bottom;
		transform-origin: right bottom;
		-webkit-transform: rotate3d(0, 0, 1, 45deg);
		transform: rotate3d(0, 0, 1, 45deg);
		opacity: 0;
	}

	to {
		-webkit-transform-origin: right bottom;
		transform-origin: right bottom;
		-webkit-transform: none;
		transform: none;
		opacity: 1;
	}
}
@-webkit-keyframes rotateInUpLeft {
	from {
		-webkit-transform-origin: left bottom;
		transform-origin: left bottom;
		-webkit-transform: rotate3d(0, 0, 1, 45deg);
		transform: rotate3d(0, 0, 1, 45deg);
		opacity: 0;
	}

	to {
		-webkit-transform-origin: left bottom;
		transform-origin: left bottom;
		-webkit-transform: none;
		transform: none;
		opacity: 1;
	}
}
@keyframes rotateInUpLeft {
	from {
		-webkit-transform-origin: left bottom;
		transform-origin: left bottom;
		-webkit-transform: rotate3d(0, 0, 1, 45deg);
		transform: rotate3d(0, 0, 1, 45deg);
		opacity: 0;
	}

	to {
		-webkit-transform-origin: left bottom;
		transform-origin: left bottom;
		-webkit-transform: none;
		transform: none;
		opacity: 1;
	}
}
@-webkit-keyframes rotateInUpRight {
	from {
		-webkit-transform-origin: right bottom;
		transform-origin: right bottom;
		-webkit-transform: rotate3d(0, 0, 1, -90deg);
		transform: rotate3d(0, 0, 1, -90deg);
		opacity: 0;
	}

	to {
		-webkit-transform-origin: right bottom;
		transform-origin: right bottom;
		-webkit-transform: none;
		transform: none;
		opacity: 1;
	}
}
@keyframes rotateInUpRight {
	from {
		-webkit-transform-origin: right bottom;
		transform-origin: right bottom;
		-webkit-transform: rotate3d(0, 0, 1, -90deg);
		transform: rotate3d(0, 0, 1, -90deg);
		opacity: 0;
	}

	to {
		-webkit-transform-origin: right bottom;
		transform-origin: right bottom;
		-webkit-transform: none;
		transform: none;
		opacity: 1;
	}
}
@-webkit-keyframes rotateOut {
	from {
		-webkit-transform-origin: center;
		transform-origin: center;
		opacity: 1;
	}

	to {
		-webkit-transform-origin: center;
		transform-origin: center;
		-webkit-transform: rotate3d(0, 0, 1, 200deg);
		transform: rotate3d(0, 0, 1, 200deg);
		opacity: 0;
	}
}
@keyframes rotateOut {
	from {
		-webkit-transform-origin: center;
		transform-origin: center;
		opacity: 1;
	}

	to {
		-webkit-transform-origin: center;
		transform-origin: center;
		-webkit-transform: rotate3d(0, 0, 1, 200deg);
		transform: rotate3d(0, 0, 1, 200deg);
		opacity: 0;
	}
}
@-webkit-keyframes rotateOutDownLeft {
	from {
		-webkit-transform-origin: left bottom;
		transform-origin: left bottom;
		opacity: 1;
	}

	to {
		-webkit-transform-origin: left bottom;
		transform-origin: left bottom;
		-webkit-transform: rotate3d(0, 0, 1, 45deg);
		transform: rotate3d(0, 0, 1, 45deg);
		opacity: 0;
	}
}
@keyframes rotateOutDownLeft {
	from {
		-webkit-transform-origin: left bottom;
		transform-origin: left bottom;
		opacity: 1;
	}

	to {
		-webkit-transform-origin: left bottom;
		transform-origin: left bottom;
		-webkit-transform: rotate3d(0, 0, 1, 45deg);
		transform: rotate3d(0, 0, 1, 45deg);
		opacity: 0;
	}
}
@-webkit-keyframes rotateOutDownRight {
	from {
		-webkit-transform-origin: right bottom;
		transform-origin: right bottom;
		opacity: 1;
	}

	to {
		-webkit-transform-origin: right bottom;
		transform-origin: right bottom;
		-webkit-transform: rotate3d(0, 0, 1, -45deg);
		transform: rotate3d(0, 0, 1, -45deg);
		opacity: 0;
	}
}
@keyframes rotateOutDownRight {
	from {
		-webkit-transform-origin: right bottom;
		transform-origin: right bottom;
		opacity: 1;
	}

	to {
		-webkit-transform-origin: right bottom;
		transform-origin: right bottom;
		-webkit-transform: rotate3d(0, 0, 1, -45deg);
		transform: rotate3d(0, 0, 1, -45deg);
		opacity: 0;
	}
}
@-webkit-keyframes rotateOutUpLeft {
	from {
		-webkit-transform-origin: left bottom;
		transform-origin: left bottom;
		opacity: 1;
	}

	to {
		-webkit-transform-origin: left bottom;
		transform-origin: left bottom;
		-webkit-transform: rotate3d(0, 0, 1, -45deg);
		transform: rotate3d(0, 0, 1, -45deg);
		opacity: 0;
	}
}
@keyframes rotateOutUpLeft {
	from {
		-webkit-transform-origin: left bottom;
		transform-origin: left bottom;
		opacity: 1;
	}

	to {
		-webkit-transform-origin: left bottom;
		transform-origin: left bottom;
		-webkit-transform: rotate3d(0, 0, 1, -45deg);
		transform: rotate3d(0, 0, 1, -45deg);
		opacity: 0;
	}
}
@-webkit-keyframes rotateOutUpRight {
	from {
		-webkit-transform-origin: right bottom;
		transform-origin: right bottom;
		opacity: 1;
	}

	to {
		-webkit-transform-origin: right bottom;
		transform-origin: right bottom;
		-webkit-transform: rotate3d(0, 0, 1, 90deg);
		transform: rotate3d(0, 0, 1, 90deg);
		opacity: 0;
	}
}
@keyframes rotateOutUpRight {
	from {
		-webkit-transform-origin: right bottom;
		transform-origin: right bottom;
		opacity: 1;
	}

	to {
		-webkit-transform-origin: right bottom;
		transform-origin: right bottom;
		-webkit-transform: rotate3d(0, 0, 1, 90deg);
		transform: rotate3d(0, 0, 1, 90deg);
		opacity: 0;
	}
}
@-webkit-keyframes hinge {
	to {
		-webkit-transform: translate3d(0, 700px, 0);
		transform: translate3d(0, 700px, 0);
		opacity: 0;
	}
}
@keyframes hinge {
	0% {
		-webkit-transform-origin: top left;
		transform-origin: top left;
		-webkit-animation-timing-function: ease-in-out;
		animation-timing-function: ease-in-out;
	}

	20%,
	60% {
		-webkit-transform: rotate3d(0, 0, 1, 80deg);
		transform: rotate3d(0, 0, 1, 80deg);
		-webkit-transform-origin: top left;
		transform-origin: top left;
		-webkit-animation-timing-function: ease-in-out;
		animation-timing-function: ease-in-out;
	}

	40%,
	80% {
		-webkit-transform: rotate3d(0, 0, 1, 60deg);
		transform: rotate3d(0, 0, 1, 60deg);
		-webkit-transform-origin: top left;
		transform-origin: top left;
		-webkit-animation-timing-function: ease-in-out;
		animation-timing-function: ease-in-out;
		opacity: 1;
	}

	to {
		-webkit-transform: translate3d(0, 700px, 0);
		transform: translate3d(0, 700px, 0);
		opacity: 0;
	}
}
@-webkit-keyframes rollIn {
	from {
		opacity: 0;
		-webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
		transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
	}

	to {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}
@keyframes rollIn {
	from {
		opacity: 0;
		-webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
		transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
	}

	to {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}
@-webkit-keyframes rollOut {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
		-webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
		transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
	}
}
@keyframes rollOut {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
		-webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
		transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
	}
}
@-webkit-keyframes zoomIn {
	from {
		opacity: 0;
		-webkit-transform: scale3d(0.3, 0.3, 0.3);
		transform: scale3d(0.3, 0.3, 0.3);
	}
}
@keyframes zoomIn {
	from {
		opacity: 0;
		-webkit-transform: scale3d(0.3, 0.3, 0.3);
		transform: scale3d(0.3, 0.3, 0.3);
	}

	50% {
		opacity: 1;
	}
}
@-webkit-keyframes zoomInDown {
	from {
		opacity: 0;
		-webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
		transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
		-webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
		animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
	}
}
@keyframes zoomInDown {
	from {
		opacity: 0;
		-webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
		transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
		-webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
		animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
	}

	60% {
		opacity: 1;
		-webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
		transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
		-webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
		animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
	}
}
@-webkit-keyframes zoomInLeft {
	from {
		opacity: 0;
		-webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
		transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
		-webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
		animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
	}
}
@keyframes zoomInLeft {
	from {
		opacity: 0;
		-webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
		transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
		-webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
		animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
	}

	60% {
		opacity: 1;
		-webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
		transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
		-webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
		animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
	}
}
@-webkit-keyframes zoomInRight {
	from {
		opacity: 0;
		-webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
		transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
		-webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
		animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
	}
}
@keyframes zoomInRight {
	from {
		opacity: 0;
		-webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
		transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
		-webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
		animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
	}

	60% {
		opacity: 1;
		-webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
		transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
		-webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
		animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
	}
}
@-webkit-keyframes zoomInUp {
	from {
		opacity: 0;
		-webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
		transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
		-webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
		animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
	}
}
@keyframes zoomInUp {
	from {
		opacity: 0;
		-webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
		transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
		-webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
		animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
	}

	60% {
		opacity: 1;
		-webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
		transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
		-webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
		animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
	}
}
@-webkit-keyframes zoomOut {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
}
@keyframes zoomOut {
	from {
		opacity: 1;
	}

	50% {
		opacity: 0;
		-webkit-transform: scale3d(0.3, 0.3, 0.3);
		transform: scale3d(0.3, 0.3, 0.3);
	}

	to {
		opacity: 0;
	}
}
@-webkit-keyframes zoomOutDown {
	to {
		opacity: 0;
		-webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
		transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
		-webkit-transform-origin: center bottom;
		transform-origin: center bottom;
		-webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
		animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
	}
}
@keyframes zoomOutDown {
	40% {
		opacity: 1;
		-webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
		transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
		-webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
		animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
	}

	to {
		opacity: 0;
		-webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
		transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
		-webkit-transform-origin: center bottom;
		transform-origin: center bottom;
		-webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
		animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
	}
}
@-webkit-keyframes zoomOutLeft {
	to {
		opacity: 0;
		-webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
		transform: scale(0.1) translate3d(-2000px, 0, 0);
		-webkit-transform-origin: left center;
		transform-origin: left center;
	}
}
@keyframes zoomOutLeft {
	40% {
		opacity: 1;
		-webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
		transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
	}

	to {
		opacity: 0;
		-webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
		transform: scale(0.1) translate3d(-2000px, 0, 0);
		-webkit-transform-origin: left center;
		transform-origin: left center;
	}
}
@-webkit-keyframes zoomOutRight {
	to {
		opacity: 0;
		-webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
		transform: scale(0.1) translate3d(2000px, 0, 0);
		-webkit-transform-origin: right center;
		transform-origin: right center;
	}
}
@keyframes zoomOutRight {
	40% {
		opacity: 1;
		-webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
		transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
	}

	to {
		opacity: 0;
		-webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
		transform: scale(0.1) translate3d(2000px, 0, 0);
		-webkit-transform-origin: right center;
		transform-origin: right center;
	}
}
@-webkit-keyframes zoomOutUp {
	to {
		opacity: 0;
		-webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
		transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
		-webkit-transform-origin: center bottom;
		transform-origin: center bottom;
		-webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
		animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
	}
}
@keyframes zoomOutUp {
	40% {
		opacity: 1;
		-webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
		transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
		-webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
		animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
	}

	to {
		opacity: 0;
		-webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
		transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
		-webkit-transform-origin: center bottom;
		transform-origin: center bottom;
		-webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
		animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
	}
}
@-webkit-keyframes slideInDown {
	from {
		-webkit-transform: translate3d(0, -100%, 0);
		transform: translate3d(0, -100%, 0);
		visibility: visible;
	}

	to {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}
@keyframes slideInDown {
	from {
		-webkit-transform: translate3d(0, -100%, 0);
		transform: translate3d(0, -100%, 0);
		visibility: visible;
	}

	to {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}
@-webkit-keyframes slideInLeft {
	from {
		-webkit-transform: translate3d(-100%, 0, 0);
		transform: translate3d(-100%, 0, 0);
		visibility: visible;
	}

	to {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}
@keyframes slideInLeft {
	from {
		-webkit-transform: translate3d(-100%, 0, 0);
		transform: translate3d(-100%, 0, 0);
		visibility: visible;
	}

	to {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}
@-webkit-keyframes slideInRight {
	from {
		-webkit-transform: translate3d(100%, 0, 0);
		transform: translate3d(100%, 0, 0);
		visibility: visible;
	}

	to {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}
@keyframes slideInRight {
	from {
		-webkit-transform: translate3d(100%, 0, 0);
		transform: translate3d(100%, 0, 0);
		visibility: visible;
	}

	to {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}
@-webkit-keyframes slideInUp {
	from {
		-webkit-transform: translate3d(0, 100%, 0);
		transform: translate3d(0, 100%, 0);
		visibility: visible;
	}

	to {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}
@keyframes slideInUp {
	from {
		-webkit-transform: translate3d(0, 100%, 0);
		transform: translate3d(0, 100%, 0);
		visibility: visible;
	}

	to {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}
@-webkit-keyframes slideOutDown {
	from {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	to {
		visibility: hidden;
		-webkit-transform: translate3d(0, 100%, 0);
		transform: translate3d(0, 100%, 0);
	}
}
@keyframes slideOutDown {
	from {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	to {
		visibility: hidden;
		-webkit-transform: translate3d(0, 100%, 0);
		transform: translate3d(0, 100%, 0);
	}
}
@-webkit-keyframes slideOutLeft {
	from {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	to {
		visibility: hidden;
		-webkit-transform: translate3d(-100%, 0, 0);
		transform: translate3d(-100%, 0, 0);
	}
}
@keyframes slideOutLeft {
	from {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	to {
		visibility: hidden;
		-webkit-transform: translate3d(-100%, 0, 0);
		transform: translate3d(-100%, 0, 0);
	}
}
@-webkit-keyframes slideOutRight {
	from {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	to {
		visibility: hidden;
		-webkit-transform: translate3d(100%, 0, 0);
		transform: translate3d(100%, 0, 0);
	}
}
@keyframes slideOutRight {
	from {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	to {
		visibility: hidden;
		-webkit-transform: translate3d(100%, 0, 0);
		transform: translate3d(100%, 0, 0);
	}
}
@-webkit-keyframes slideOutUp {
	from {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	to {
		visibility: hidden;
		-webkit-transform: translate3d(0, -100%, 0);
		transform: translate3d(0, -100%, 0);
	}
}
@keyframes slideOutUp {
	from {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	to {
		visibility: hidden;
		-webkit-transform: translate3d(0, -100%, 0);
		transform: translate3d(0, -100%, 0);
	}
}
.owl-carousel,
.owl-carousel .owl-item {
	-webkit-tap-highlight-color: transparent;
	position: relative;
}
.owl-carousel {
	display: none;
	width: 100%;
	z-index: 1;
}
.owl-carousel .owl-stage {
	position: relative;
	-ms-touch-action: pan-Y;
	touch-action: manipulation;
	-moz-backface-visibility: hidden;
}
.owl-carousel .owl-stage:after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}
.owl-carousel .owl-stage-outer {
	position: relative;
	overflow: hidden;
	-webkit-transform: translate3d(0, 0, 0);
}
.owl-carousel .owl-item {
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-ms-backface-visibility: hidden;
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
}
.owl-carousel .owl-item {
	min-height: 1px;
	float: left;
	-webkit-backface-visibility: hidden;
	-webkit-touch-callout: none;
}
.owl-carousel .owl-item img {
	display: block;
	width: 100%;
}
.owl-carousel .owl-dots.disabled,
.owl-carousel .owl-nav.disabled {
	display: none;
}
.owl-carousel.owl-loaded {
	display: block;
}
.owl-carousel .owl-dot,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-nav .owl-prev {
	cursor: pointer;
	cursor: hand;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel button.owl-dot {
	background: 0 0;
	color: inherit;
	border: none;
	padding: 0 !important;
	font: inherit;
}
.owl-carousel.owl-loading {
	opacity: 0;
	display: block;
}
.owl-carousel.owl-hidden {
	opacity: 0;
}
.owl-carousel.owl-refresh .owl-item {
	visibility: hidden;
}
.owl-carousel.owl-drag .owl-item {
	-ms-touch-action: none;
	touch-action: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.owl-carousel.owl-grab {
	cursor: move;
	cursor: grab;
}
.owl-carousel.owl-rtl {
	direction: rtl;
}
.owl-carousel.owl-rtl .owl-item {
	float: right;
}
.owl-carousel .animated {
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}
.owl-carousel .owl-animated-in {
	z-index: 0;
}
.owl-carousel .owl-animated-out {
	z-index: 1;
}
.owl-carousel .fadeOut {
	-webkit-animation-name: fadeOut;
	animation-name: fadeOut;
}
@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
.owl-height {
	transition: height 0.5s ease-in-out;
}
.owl-carousel .owl-item .owl-lazy {
	opacity: 0;
	transition: opacity 0.4s ease;
}
.owl-carousel .owl-item img.owl-lazy {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
}
.owl-carousel .owl-video-wrapper {
	position: relative;
	height: 100%;
	background: #000;
}
.owl-carousel .owl-video-play-icon {
	position: absolute;
	height: 80px;
	width: 80px;
	left: 50%;
	top: 50%;
	margin-left: -40px;
	margin-top: -40px;
	background: url() no-repeat;
	cursor: pointer;
	z-index: 1;
	-webkit-backface-visibility: hidden;
	transition: -webkit-transform 0.1s ease;
	transition: transform 0.1s ease;
}
.owl-carousel .owl-video-play-icon:hover {
	-webkit-transform: scale(1.3, 1.3);
	-ms-transform: scale(1.3, 1.3);
	transform: scale(1.3, 1.3);
}
.owl-carousel .owl-video-playing .owl-video-play-icon,
.owl-carousel .owl-video-playing .owl-video-tn {
	display: none;
}
.owl-carousel .owl-video-tn {
	opacity: 0;
	height: 100%;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
	transition: opacity 0.4s ease;
}
.owl-carousel .owl-video-frame {
	position: relative;
	z-index: 1;
	height: 100%;
	width: 100%;
}
.mfp-bg {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1042;
	overflow: hidden;
	position: fixed;
	background: #0b0b0b;
	opacity: 0.8;
}
.mfp-wrap {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1043;
	position: fixed;
	outline: none !important;
	-webkit-backface-visibility: hidden;
}
.mfp-container {
	text-align: center;
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	padding: 0 8px;
	box-sizing: border-box;
}
.mfp-container:before {
	content: "";
	display: inline-block;
	height: 100%;
	vertical-align: middle;
}
.mfp-align-top .mfp-container:before {
	display: none;
}
.mfp-ajax-cur {
	cursor: progress;
}
.mfp-zoom-out-cur {
	cursor: -moz-zoom-out;
	cursor: -webkit-zoom-out;
	cursor: zoom-out;
}
.mfp-close,
.mfp-arrow,
.mfp-counter {
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
}
.mfp-loading.mfp-figure {
	display: none;
}
button.mfp-close,
button.mfp-arrow {
	overflow: visible;
	cursor: pointer;
	background: transparent;
	border: 0;
	-webkit-appearance: none;
	display: block;
	outline: none;
	padding: 0;
	z-index: 1046;
	box-shadow: none;
	touch-action: manipulation;
}
button::-moz-focus-inner {
	padding: 0;
	border: 0;
}
.mfp-close {
	width: 44px;
	height: 44px;
	line-height: 44px;
	position: absolute;
	right: 0;
	top: 0;
	text-decoration: none;
	text-align: center;
	opacity: 0.65;
	padding: 0 0 18px 10px;
	color: #fff;
	font-style: normal;
	font-size: 28px;
	font-family: Arial, Baskerville, monospace;
}
.mfp-close:hover,
.mfp-close:focus {
	opacity: 1;
}
.mfp-close:active {
	top: 1px;
}
.mfp-close-btn-in .mfp-close {
	color: #333;
}
.mfp-counter {
	position: absolute;
	top: 0;
	right: 0;
	color: #ccc;
	font-size: 12px;
	line-height: 18px;
	white-space: nowrap;
}
.mfp-arrow {
	position: absolute;
	opacity: 0.65;
	margin: 0;
	top: 50%;
	margin-top: -55px;
	padding: 0;
	width: 90px;
	height: 110px;
	-webkit-tap-highlight-color: transparent;
}
.mfp-arrow:active {
	margin-top: -54px;
}
.mfp-arrow:hover,
.mfp-arrow:focus {
	opacity: 1;
}
.mfp-arrow:before,
.mfp-arrow:after {
	content: "";
	display: block;
	width: 0;
	height: 0;
	position: absolute;
	left: 0;
	top: 0;
	margin-top: 35px;
	margin-left: 35px;
	border: medium inset transparent;
}
.mfp-arrow:after {
	border-top-width: 13px;
	border-bottom-width: 13px;
	top: 8px;
}
.mfp-arrow:before {
	border-top-width: 21px;
	border-bottom-width: 21px;
	opacity: 0.7;
}
.mfp-iframe-scaler {
	width: 100%;
	height: 0;
	overflow: hidden;
	padding-top: 56.25%;
}
.mfp-iframe-scaler iframe {
	position: absolute;
	display: block;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
	background: #000;
}
img.mfp-img {
	width: auto;
	max-width: 100%;
	height: auto;
	display: block;
	line-height: 0;
	box-sizing: border-box;
	padding: 40px 0 40px;
	margin: 0 auto;
}
.mfp-figure {
	line-height: 0;
}
.mfp-figure:after {
	content: "";
	position: absolute;
	left: 0;
	top: 40px;
	bottom: 40px;
	display: block;
	right: 0;
	width: auto;
	height: auto;
	z-index: -1;
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
	background: #444;
}
.mfp-figure small {
	color: #bdbdbd;
	display: block;
	font-size: 12px;
	line-height: 14px;
}
.mfp-figure figure {
	margin: 0;
}
.mfp-bottom-bar {
	margin-top: -36px;
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	cursor: auto;
}
.mfp-title {
	text-align: left;
	line-height: 18px;
	color: #f3f3f3;
	word-wrap: break-word;
	padding-right: 36px;
}
@media all and (max-width: 900px) {
	.mfp-arrow {
		-webkit-transform: scale(0.75);
		transform: scale(0.75);
	}
	.mfp-container {
		padding-left: 6px;
		padding-right: 6px;
	}
}
[data-aos][data-aos][data-aos-duration="50"],
body[data-aos-duration="50"] [data-aos] {
	transition-duration: 50ms;
}
[data-aos][data-aos][data-aos-delay="50"],
body[data-aos-delay="50"] [data-aos] {
	transition-delay: 0;
}
[data-aos][data-aos][data-aos-delay="50"].aos-animate,
body[data-aos-delay="50"] [data-aos].aos-animate {
	transition-delay: 50ms;
}
[data-aos][data-aos][data-aos-duration="100"],
body[data-aos-duration="100"] [data-aos] {
	transition-duration: 0.1s;
}
[data-aos][data-aos][data-aos-delay="100"],
body[data-aos-delay="100"] [data-aos] {
	transition-delay: 0;
}
[data-aos][data-aos][data-aos-delay="100"].aos-animate,
body[data-aos-delay="100"] [data-aos].aos-animate {
	transition-delay: 0.1s;
}
[data-aos][data-aos][data-aos-duration="150"],
body[data-aos-duration="150"] [data-aos] {
	transition-duration: 0.15s;
}
[data-aos][data-aos][data-aos-delay="150"],
body[data-aos-delay="150"] [data-aos] {
	transition-delay: 0;
}
[data-aos][data-aos][data-aos-delay="150"].aos-animate,
body[data-aos-delay="150"] [data-aos].aos-animate {
	transition-delay: 0.15s;
}
[data-aos][data-aos][data-aos-duration="200"],
body[data-aos-duration="200"] [data-aos] {
	transition-duration: 0.2s;
}
[data-aos][data-aos][data-aos-delay="200"],
body[data-aos-delay="200"] [data-aos] {
	transition-delay: 0;
}
[data-aos][data-aos][data-aos-delay="200"].aos-animate,
body[data-aos-delay="200"] [data-aos].aos-animate {
	transition-delay: 0.2s;
}
[data-aos][data-aos][data-aos-duration="250"],
body[data-aos-duration="250"] [data-aos] {
	transition-duration: 0.25s;
}
[data-aos][data-aos][data-aos-delay="250"],
body[data-aos-delay="250"] [data-aos] {
	transition-delay: 0;
}
[data-aos][data-aos][data-aos-delay="250"].aos-animate,
body[data-aos-delay="250"] [data-aos].aos-animate {
	transition-delay: 0.25s;
}
[data-aos][data-aos][data-aos-duration="300"],
body[data-aos-duration="300"] [data-aos] {
	transition-duration: 0.3s;
}
[data-aos][data-aos][data-aos-delay="300"],
body[data-aos-delay="300"] [data-aos] {
	transition-delay: 0;
}
[data-aos][data-aos][data-aos-delay="300"].aos-animate,
body[data-aos-delay="300"] [data-aos].aos-animate {
	transition-delay: 0.3s;
}
[data-aos][data-aos][data-aos-duration="400"],
body[data-aos-duration="400"] [data-aos] {
	transition-duration: 0.4s;
}
[data-aos][data-aos][data-aos-delay="400"],
body[data-aos-delay="400"] [data-aos] {
	transition-delay: 0;
}
[data-aos][data-aos][data-aos-delay="400"].aos-animate,
body[data-aos-delay="400"] [data-aos].aos-animate {
	transition-delay: 0.4s;
}
[data-aos][data-aos][data-aos-duration="450"],
body[data-aos-duration="450"] [data-aos] {
	transition-duration: 0.45s;
}
[data-aos][data-aos][data-aos-delay="450"],
body[data-aos-delay="450"] [data-aos] {
	transition-delay: 0;
}
[data-aos][data-aos][data-aos-delay="450"].aos-animate,
body[data-aos-delay="450"] [data-aos].aos-animate {
	transition-delay: 0.45s;
}
[data-aos][data-aos][data-aos-duration="500"],
body[data-aos-duration="500"] [data-aos] {
	transition-duration: 0.5s;
}
[data-aos][data-aos][data-aos-delay="500"],
body[data-aos-delay="500"] [data-aos] {
	transition-delay: 0;
}
[data-aos][data-aos][data-aos-delay="500"].aos-animate,
body[data-aos-delay="500"] [data-aos].aos-animate {
	transition-delay: 0.5s;
}
[data-aos][data-aos][data-aos-duration="600"],
body[data-aos-duration="600"] [data-aos] {
	transition-duration: 0.6s;
}
[data-aos][data-aos][data-aos-delay="600"],
body[data-aos-delay="600"] [data-aos] {
	transition-delay: 0;
}
[data-aos][data-aos][data-aos-delay="600"].aos-animate,
body[data-aos-delay="600"] [data-aos].aos-animate {
	transition-delay: 0.6s;
}
[data-aos][data-aos][data-aos-duration="700"],
body[data-aos-duration="700"] [data-aos] {
	transition-duration: 0.7s;
}
[data-aos][data-aos][data-aos-delay="700"],
body[data-aos-delay="700"] [data-aos] {
	transition-delay: 0;
}
[data-aos][data-aos][data-aos-delay="700"].aos-animate,
body[data-aos-delay="700"] [data-aos].aos-animate {
	transition-delay: 0.7s;
}
[data-aos][data-aos][data-aos-duration="800"],
body[data-aos-duration="800"] [data-aos] {
	transition-duration: 0.8s;
}
[data-aos][data-aos][data-aos-delay="800"],
body[data-aos-delay="800"] [data-aos] {
	transition-delay: 0;
}
[data-aos][data-aos][data-aos-delay="800"].aos-animate,
body[data-aos-delay="800"] [data-aos].aos-animate {
	transition-delay: 0.8s;
}
[data-aos][data-aos][data-aos-duration="850"],
body[data-aos-duration="850"] [data-aos] {
	transition-duration: 0.85s;
}
[data-aos][data-aos][data-aos-delay="850"],
body[data-aos-delay="850"] [data-aos] {
	transition-delay: 0;
}
[data-aos][data-aos][data-aos-delay="850"].aos-animate,
body[data-aos-delay="850"] [data-aos].aos-animate {
	transition-delay: 0.85s;
}
[data-aos][data-aos][data-aos-duration="900"],
body[data-aos-duration="900"] [data-aos] {
	transition-duration: 0.9s;
}
[data-aos][data-aos][data-aos-delay="900"],
body[data-aos-delay="900"] [data-aos] {
	transition-delay: 0;
}
[data-aos][data-aos][data-aos-delay="900"].aos-animate,
body[data-aos-delay="900"] [data-aos].aos-animate {
	transition-delay: 0.9s;
}
[data-aos][data-aos][data-aos-duration="1000"],
body[data-aos-duration="1000"] [data-aos] {
	transition-duration: 1s;
}
[data-aos][data-aos][data-aos-delay="1000"],
body[data-aos-delay="1000"] [data-aos] {
	transition-delay: 0;
}
[data-aos][data-aos][data-aos-delay="1000"].aos-animate,
body[data-aos-delay="1000"] [data-aos].aos-animate {
	transition-delay: 1s;
}
[data-aos][data-aos][data-aos-duration="1450"],
body[data-aos-duration="1450"] [data-aos] {
	transition-duration: 1.45s;
}
[data-aos][data-aos][data-aos-delay="1450"],
body[data-aos-delay="1450"] [data-aos] {
	transition-delay: 0;
}
[data-aos][data-aos][data-aos-delay="1450"].aos-animate,
body[data-aos-delay="1450"] [data-aos].aos-animate {
	transition-delay: 1.45s;
}
[data-aos][data-aos][data-aos-duration="2000"],
body[data-aos-duration="2000"] [data-aos] {
	transition-duration: 2s;
}
[data-aos][data-aos][data-aos-delay="2000"],
body[data-aos-delay="2000"] [data-aos] {
	transition-delay: 0;
}
[data-aos][data-aos][data-aos-delay="2000"].aos-animate,
body[data-aos-delay="2000"] [data-aos].aos-animate {
	transition-delay: 2s;
}
[data-aos][data-aos][data-aos-easing="linear"],
body[data-aos-easing="linear"] [data-aos] {
	transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
}
[data-aos][data-aos][data-aos-easing="ease"],
body[data-aos-easing="ease"] [data-aos] {
	transition-timing-function: ease;
}
[data-aos][data-aos][data-aos-easing="ease-in-out"],
body[data-aos-easing="ease-in-out"] [data-aos] {
	transition-timing-function: ease-in-out;
}
[data-aos^="fade"][data-aos^="fade"] {
	opacity: 0;
	transition-property: opacity, transform;
}
[data-aos^="fade"][data-aos^="fade"].aos-animate {
	opacity: 1;
	transform: translate(0);
}
[data-aos^="zoom"][data-aos^="zoom"] {
	opacity: 0;
	transition-property: opacity, transform;
}
[data-aos^="zoom"][data-aos^="zoom"].aos-animate {
	opacity: 1;
	transform: translate(0) scale(1);
}
[data-aos^="slide"][data-aos^="slide"] {
	transition-property: transform;
}
[data-aos^="slide"][data-aos^="slide"].aos-animate {
	transform: translate(0);
}
[data-aos^="flip"][data-aos^="flip"] {
	backface-visibility: hidden;
	transition-property: transform;
}
.ionicons,
.ion-ios-arrow-back:before,
.ion-ios-arrow-down:before,
.ion-ios-arrow-forward:before,
.ion-ios-arrow-up:before,
.ion-md-arrow-back:before {
	display: inline-block;
	font-family: "Ionicons";
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	text-rendering: auto;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.ion-ios-arrow-back:before {
	content: "\f3cf";
}
.ion-ios-arrow-down:before {
	content: "\f3d0";
}
.ion-ios-arrow-forward:before {
	content: "\f3d1";
}
.ion-ios-arrow-up:before {
	content: "\f3d8";
}
.ion-md-arrow-back:before {
	content: "\f27d";
}
.datepicker {
	padding: 4px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	direction: ltr;
}
.datepicker-inline {
	width: 220px;
}
.datepicker.datepicker-rtl {
	direction: rtl;
}
.datepicker.datepicker-rtl table tr td span {
	float: right;
}
.datepicker-dropdown {
	top: 0;
	left: 0;
}
.datepicker-dropdown:before {
	content: "";
	display: inline-block;
	border-left: 7px solid transparent;
	border-right: 7px solid transparent;
	border-bottom: 7px solid #ccc;
	border-top: 0;
	border-bottom-color: rgba(0, 0, 0, 0.2);
	position: absolute;
}
.datepicker-dropdown:after {
	content: "";
	display: inline-block;
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-bottom: 6px solid #fff;
	border-top: 0;
	position: absolute;
}
.datepicker-dropdown.datepicker-orient-left:before {
	left: 6px;
}
.datepicker-dropdown.datepicker-orient-left:after {
	left: 7px;
}
.datepicker-dropdown.datepicker-orient-right:before {
	right: 6px;
}
.datepicker-dropdown.datepicker-orient-right:after {
	right: 7px;
}
.datepicker-dropdown.datepicker-orient-top:before {
	top: -7px;
}
.datepicker-dropdown.datepicker-orient-top:after {
	top: -6px;
}
.datepicker-dropdown.datepicker-orient-bottom:before {
	bottom: -7px;
	border-bottom: 0;
	border-top: 7px solid #999;
}
.datepicker-dropdown.datepicker-orient-bottom:after {
	bottom: -6px;
	border-bottom: 0;
	border-top: 6px solid #fff;
}
.datepicker > div {
	display: none;
}
.datepicker.days div.datepicker-days {
	display: block;
}
.datepicker.months div.datepicker-months {
	display: block;
}
.datepicker.years div.datepicker-years {
	display: block;
}
.datepicker table {
	margin: 0;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.datepicker td,
.datepicker th {
	text-align: center;
	width: 20px;
	height: 20px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	border: none;
}
.datepicker table tr td.day:hover,
.datepicker table tr td.day.focused {
	background: #eee;
	cursor: pointer;
}
.datepicker table tr td.old,
.datepicker table tr td.new {
	color: #999;
}
.datepicker table tr td.disabled,
.datepicker table tr td.disabled:hover {
	background: none;
	color: #999;
	cursor: default;
}
.datepicker table tr td.today,
.datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover {
	background-color: #fde19a;
	background-image: -moz-linear-gradient(top, #fdd49a, #fdf59a);
	background-image: -ms-linear-gradient(top, #fdd49a, #fdf59a);
	background-image: -webkit-gradient(
		linear,
		0 0,
		0 100%,
		from(#fdd49a),
		to(#fdf59a)
	);
	background-image: -webkit-linear-gradient(top, #fdd49a, #fdf59a);
	background-image: -o-linear-gradient(top, #fdd49a, #fdf59a);
	background-image: linear-gradient(top, #fdd49a, #fdf59a);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fdd49a', endColorstr='#fdf59a', GradientType=0);
	border-color: #fdf59a #fdf59a #fbed50;
	border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
	filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
	color: #000;
}
.datepicker table tr td.today:hover,
.datepicker table tr td.today:hover:hover,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today.disabled:hover:hover,
.datepicker table tr td.today:active,
.datepicker table tr td.today:hover:active,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today.active,
.datepicker table tr td.today:hover.active,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled:hover.active,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today:hover.disabled,
.datepicker table tr td.today.disabled.disabled,
.datepicker table tr td.today.disabled:hover.disabled,
.datepicker table tr td.today[disabled],
.datepicker table tr td.today:hover[disabled],
.datepicker table tr td.today.disabled[disabled],
.datepicker table tr td.today.disabled:hover[disabled] {
	background-color: #fdf59a;
}
.datepicker table tr td.today:active,
.datepicker table tr td.today:hover:active,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today.active,
.datepicker table tr td.today:hover.active,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled:hover.active {
	background-color: #fbf069 \9;
}
.datepicker table tr td.today:hover:hover {
	color: #000;
}
.datepicker table tr td.today.active:hover {
	color: #fff;
}
.datepicker table tr td.range,
.datepicker table tr td.range:hover,
.datepicker table tr td.range.disabled,
.datepicker table tr td.range.disabled:hover {
	background: #eee;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
}
.datepicker table tr td.range.today,
.datepicker table tr td.range.today:hover,
.datepicker table tr td.range.today.disabled,
.datepicker table tr td.range.today.disabled:hover {
	background-color: #f3d17a;
	background-image: -moz-linear-gradient(top, #f3c17a, #f3e97a);
	background-image: -ms-linear-gradient(top, #f3c17a, #f3e97a);
	background-image: -webkit-gradient(
		linear,
		0 0,
		0 100%,
		from(#f3c17a),
		to(#f3e97a)
	);
	background-image: -webkit-linear-gradient(top, #f3c17a, #f3e97a);
	background-image: -o-linear-gradient(top, #f3c17a, #f3e97a);
	background-image: linear-gradient(top, #f3c17a, #f3e97a);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f3c17a', endColorstr='#f3e97a', GradientType=0);
	border-color: #f3e97a #f3e97a #edde34;
	border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
	filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
}
.datepicker table tr td.range.today:hover,
.datepicker table tr td.range.today:hover:hover,
.datepicker table tr td.range.today.disabled:hover,
.datepicker table tr td.range.today.disabled:hover:hover,
.datepicker table tr td.range.today:active,
.datepicker table tr td.range.today:hover:active,
.datepicker table tr td.range.today.disabled:active,
.datepicker table tr td.range.today.disabled:hover:active,
.datepicker table tr td.range.today.active,
.datepicker table tr td.range.today:hover.active,
.datepicker table tr td.range.today.disabled.active,
.datepicker table tr td.range.today.disabled:hover.active,
.datepicker table tr td.range.today.disabled,
.datepicker table tr td.range.today:hover.disabled,
.datepicker table tr td.range.today.disabled.disabled,
.datepicker table tr td.range.today.disabled:hover.disabled,
.datepicker table tr td.range.today[disabled],
.datepicker table tr td.range.today:hover[disabled],
.datepicker table tr td.range.today.disabled[disabled],
.datepicker table tr td.range.today.disabled:hover[disabled] {
	background-color: #f3e97a;
}
.datepicker table tr td.range.today:active,
.datepicker table tr td.range.today:hover:active,
.datepicker table tr td.range.today.disabled:active,
.datepicker table tr td.range.today.disabled:hover:active,
.datepicker table tr td.range.today.active,
.datepicker table tr td.range.today:hover.active,
.datepicker table tr td.range.today.disabled.active,
.datepicker table tr td.range.today.disabled:hover.active {
	background-color: #efe24b \9;
}
.datepicker table tr td.selected,
.datepicker table tr td.selected:hover,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected.disabled:hover {
	background-color: #9e9e9e;
	background-image: -moz-linear-gradient(top, #b3b3b3, gray);
	background-image: -ms-linear-gradient(top, #b3b3b3, gray);
	background-image: -webkit-gradient(
		linear,
		0 0,
		0 100%,
		from(#b3b3b3),
		to(gray)
	);
	background-image: -webkit-linear-gradient(top, #b3b3b3, gray);
	background-image: -o-linear-gradient(top, #b3b3b3, gray);
	background-image: linear-gradient(top, #b3b3b3, gray);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b3b3b3', endColorstr='#808080', GradientType=0);
	border-color: gray gray #595959;
	border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
	filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
	color: #fff;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.datepicker table tr td.selected:hover,
.datepicker table tr td.selected:hover:hover,
.datepicker table tr td.selected.disabled:hover,
.datepicker table tr td.selected.disabled:hover:hover,
.datepicker table tr td.selected:active,
.datepicker table tr td.selected:hover:active,
.datepicker table tr td.selected.disabled:active,
.datepicker table tr td.selected.disabled:hover:active,
.datepicker table tr td.selected.active,
.datepicker table tr td.selected:hover.active,
.datepicker table tr td.selected.disabled.active,
.datepicker table tr td.selected.disabled:hover.active,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected:hover.disabled,
.datepicker table tr td.selected.disabled.disabled,
.datepicker table tr td.selected.disabled:hover.disabled,
.datepicker table tr td.selected[disabled],
.datepicker table tr td.selected:hover[disabled],
.datepicker table tr td.selected.disabled[disabled],
.datepicker table tr td.selected.disabled:hover[disabled] {
	background-color: gray;
}
.datepicker table tr td.selected:active,
.datepicker table tr td.selected:hover:active,
.datepicker table tr td.selected.disabled:active,
.datepicker table tr td.selected.disabled:hover:active,
.datepicker table tr td.selected.active,
.datepicker table tr td.selected:hover.active,
.datepicker table tr td.selected.disabled.active,
.datepicker table tr td.selected.disabled:hover.active {
	background-color: #666 \9;
}
.datepicker table tr td.active,
.datepicker table tr td.active:hover,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover {
	background-color: #006dcc;
	background-image: -moz-linear-gradient(top, #08c, #04c);
	background-image: -ms-linear-gradient(top, #08c, #04c);
	background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#08c), to(#04c));
	background-image: -webkit-linear-gradient(top, #08c, #04c);
	background-image: -o-linear-gradient(top, #08c, #04c);
	background-image: linear-gradient(top, #08c, #04c);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0088cc', endColorstr='#0044cc', GradientType=0);
	border-color: #04c #04c #002a80;
	border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
	filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
	color: #fff;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.datepicker table tr td.active:hover,
.datepicker table tr td.active:hover:hover,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active:hover.disabled,
.datepicker table tr td.active.disabled.disabled,
.datepicker table tr td.active.disabled:hover.disabled,
.datepicker table tr td.active[disabled],
.datepicker table tr td.active:hover[disabled],
.datepicker table tr td.active.disabled[disabled],
.datepicker table tr td.active.disabled:hover[disabled] {
	background-color: #04c;
}
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active {
	background-color: #039 \9;
}
.datepicker table tr td span {
	display: block;
	width: 23%;
	height: 54px;
	line-height: 54px;
	float: left;
	margin: 1%;
	cursor: pointer;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
}
.datepicker table tr td span:hover {
	background: #eee;
}
.datepicker table tr td span.disabled,
.datepicker table tr td span.disabled:hover {
	background: none;
	color: #999;
	cursor: default;
}
.datepicker table tr td span.active,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled:hover {
	background-color: #006dcc;
	background-image: -moz-linear-gradient(top, #08c, #04c);
	background-image: -ms-linear-gradient(top, #08c, #04c);
	background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#08c), to(#04c));
	background-image: -webkit-linear-gradient(top, #08c, #04c);
	background-image: -o-linear-gradient(top, #08c, #04c);
	background-image: linear-gradient(top, #08c, #04c);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0088cc', endColorstr='#0044cc', GradientType=0);
	border-color: #04c #04c #002a80;
	border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
	filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
	color: #fff;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active:hover.disabled,
.datepicker table tr td span.active.disabled.disabled,
.datepicker table tr td span.active.disabled:hover.disabled,
.datepicker table tr td span.active[disabled],
.datepicker table tr td span.active:hover[disabled],
.datepicker table tr td span.active.disabled[disabled],
.datepicker table tr td span.active.disabled:hover[disabled] {
	background-color: #04c;
}
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active {
	background-color: #039 \9;
}
.datepicker table tr td span.old,
.datepicker table tr td span.new {
	color: #999;
}
.datepicker th.datepicker-switch {
	width: 145px;
}
.datepicker thead tr:first-child th,
.datepicker tfoot tr th {
	cursor: pointer;
}
.datepicker thead tr:first-child th:hover,
.datepicker tfoot tr th:hover {
	background: #eee;
}
.datepicker .cw {
	font-size: 10px;
	width: 12px;
	padding: 0 2px 0 5px;
	vertical-align: middle;
}
.datepicker thead tr:first-child th.cw {
	cursor: default;
	background-color: transparent;
}
.input-daterange input {
	text-align: center;
}
.input-daterange input:first-child {
	-webkit-border-radius: 3px 0 0 3px;
	-moz-border-radius: 3px 0 0 3px;
	border-radius: 3px 0 0 3px;
}
.input-daterange input:last-child {
	-webkit-border-radius: 0 3px 3px 0;
	-moz-border-radius: 0 3px 3px 0;
	border-radius: 0 3px 3px 0;
}
.input-daterange .add-on {
	display: inline-block;
	width: auto;
	min-width: 16px;
	height: 20px;
	padding: 4px 5px;
	font-weight: normal;
	line-height: 20px;
	text-align: center;
	text-shadow: 0 1px 0 #fff;
	vertical-align: middle;
	background-color: #eee;
	border: 1px solid #ccc;
	margin-left: -5px;
	margin-right: -5px;
}
.datepicker.dropdown-menu {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 1000;
	float: left;
	display: none;
	min-width: 160px;
	list-style: none;
	background-color: #fff;
	border: 1px solid #ccc;
	border: 1px solid rgba(0, 0, 0, 0.2);
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	-webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
	-webkit-background-clip: padding-box;
	-moz-background-clip: padding;
	background-clip: padding-box;
	*border-right-width: 2px;
	*border-bottom-width: 2px;
	color: #333;
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-size: 13px;
	line-height: 20px;
}
.datepicker.dropdown-menu th,
.datepicker.dropdown-menu td {
	padding: 4px 5px;
}
[class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
	font-family: Flaticon;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.flaticon-server:before {
	content: "\f100";
}
.flaticon-cloud:before {
	content: "\f101";
}
.flaticon-cloud-computing:before {
	content: "\f102";
}
.flaticon-settings:before {
	content: "\f103";
}
.flaticon-database:before {
	content: "\f104";
}
.flaticon-life-insurance:before {
	content: "\f105";
}
.flaticon-customer-service:before {
	content: "\f106";
}
[class^="icon-"],
[class*=" icon-"] {
	font-family: "icomoon" !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.icon-search:before {
	content: "\f002";
}
.icon-heart:before {
	content: "\f004";
}
.icon-map-marker:before {
	content: "\f041";
}
.icon-calendar:before {
	content: "\f073";
}
.icon-phone:before {
	content: "\f095";
}
.icon-twitter:before {
	content: "\f099";
}
.icon-facebook:before {
	content: "\f09a";
}
.icon-envelope:before {
	content: "\f0e0";
}
.icon-quote-left:before {
	content: "\f10d";
}
.icon-instagram:before {
	content: "\f16d";
}
.icon-chat:before {
	content: "\e0b7";
}
.icon-person:before {
	content: "\e7fd";
}
:root {
	--blue: #007bff;
	--indigo: #6610f2;
	--purple: #6f42c1;
	--pink: #e83e8c;
	--red: #dc3545;
	--orange: #fd7e14;
	--yellow: #ffc107;
	--green: #28a745;
	--teal: #20c997;
	--cyan: #17a2b8;
	--white: #fff;
	--gray: #6c757d;
	--gray-dark: #343a40;
	--primary: #007bff;
	--secondary: #6c757d;
	--success: #28a745;
	--info: #17a2b8;
	--warning: #ffc107;
	--danger: #dc3545;
	--light: #f8f9fa;
	--dark: #343a40;
	--breakpoint-xs: 0;
	--breakpoint-sm: 576px;
	--breakpoint-md: 768px;
	--breakpoint-lg: 992px;
	--breakpoint-xl: 1200px;
	--font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
		Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
		"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	--font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
		"Liberation Mono", "Courier New", monospace;
}
*,
*::before,
*::after {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
html {
	font-family: sans-serif;
	line-height: 1.15;
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
figcaption,
figure,
footer,
header,
main,
nav,
section {
	display: block;
}
body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
		"Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
		"Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #212529;
	text-align: left;
	background-color: #fff;
}
[tabindex="-1"]:focus {
	outline: 0 !important;
}
hr {
	-webkit-box-sizing: content-box;
	box-sizing: content-box;
	height: 0;
	overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0;
	margin-bottom: 0.5rem;
}
p {
	margin-top: 0;
	margin-bottom: 1rem;
}
address {
	margin-bottom: 1rem;
	font-style: normal;
	line-height: inherit;
}
ol,
ul {
	margin-top: 0;
	margin-bottom: 1rem;
}
ol ol,
ul ul,
ol ul,
ul ol {
	margin-bottom: 0;
}
dt {
	font-weight: 700;
}
dd {
	margin-bottom: 0.5rem;
	margin-left: 0;
}
b,
strong {
	font-weight: bolder;
}
small {
	font-size: 80%;
}
sub,
sup {
	position: relative;
	font-size: 75%;
	line-height: 0;
	vertical-align: baseline;
}
sub {
	bottom: -0.25em;
}
sup {
	top: -0.5em;
}
a {
	color: #007bff;
	text-decoration: none;
	background-color: transparent;
}
a:hover {
	color: #0056b3;
	text-decoration: underline;
}
a:not([href]):not([tabindex]) {
	color: inherit;
	text-decoration: none;
}
a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
	color: inherit;
	text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
	outline: 0;
}
pre,
code {
	font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
		"Courier New", monospace;
	font-size: 1em;
}
pre {
	margin-top: 0;
	margin-bottom: 1rem;
	overflow: auto;
}
figure {
	margin: 0 0 1rem;
}
img {
	vertical-align: middle;
	border-style: none;
}
svg {
	overflow: hidden;
	vertical-align: middle;
}
table {
	border-collapse: collapse;
}
caption {
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
	color: #6c757d;
	text-align: left;
	caption-side: bottom;
}
th {
	text-align: inherit;
}
label {
	display: inline-block;
	margin-bottom: 0.5rem;
}
button {
	border-radius: 0;
}
button:focus {
	outline: 1px dotted;
	outline: 5px auto -webkit-focus-ring-color;
}
input,
button,
select,
optgroup,
textarea {
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}
button,
input {
	overflow: visible;
}
button,
select {
	text-transform: none;
}
select {
	word-wrap: normal;
}
button,
[type="button"],
[type="reset"],
[type="submit"] {
	-webkit-appearance: button;
}
button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
	cursor: pointer;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
	padding: 0;
	border-style: none;
}
input[type="radio"],
input[type="checkbox"] {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	padding: 0;
}
input[type="date"],
input[type="time"],
input[type="month"] {
	-webkit-appearance: listbox;
}
textarea {
	overflow: auto;
	resize: vertical;
}
fieldset {
	min-width: 0;
	padding: 0;
	margin: 0;
	border: 0;
}
legend {
	display: block;
	width: 100%;
	max-width: 100%;
	padding: 0;
	margin-bottom: 0.5rem;
	font-size: 1.5rem;
	line-height: inherit;
	color: inherit;
	white-space: normal;
}
progress {
	vertical-align: baseline;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
	height: auto;
}
[type="search"] {
	outline-offset: -2px;
	-webkit-appearance: none;
}
[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
}
output {
	display: inline-block;
}
template {
	display: none;
}
[hidden] {
	display: none !important;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	margin-bottom: 0.5rem;
	font-weight: 500;
	line-height: 1.2;
}
h1,
.h1 {
	font-size: 2.5rem;
}
h2,
.h2 {
	font-size: 2rem;
}
h3,
.h3 {
	font-size: 1.75rem;
}
h4,
.h4 {
	font-size: 1.5rem;
}
h5,
.h5 {
	font-size: 1.25rem;
}
h6,
.h6 {
	font-size: 1rem;
}
hr {
	margin-top: 1rem;
	margin-bottom: 1rem;
	border: 0;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
}
small,
.small {
	font-size: 80%;
	font-weight: 400;
}
mark,
.mark {
	padding: 0.2em;
	background-color: #fcf8e3;
}
.list-unstyled {
	padding-left: 0;
	list-style: none;
}
.img-fluid {
	max-width: 100%;
	height: auto;
}
.figure {
	display: inline-block;
}
code {
	font-size: 87.5%;
	color: #e83e8c;
	word-break: break-word;
}
a > code {
	color: inherit;
}
pre {
	display: block;
	font-size: 87.5%;
	color: #212529;
}
pre code {
	font-size: inherit;
	color: inherit;
	word-break: normal;
}
.container {
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
}
@media (min-width: 576px) {
	.container {
		max-width: 540px;
	}
}
@media (min-width: 768px) {
	.container {
		max-width: 720px;
	}
}
@media (min-width: 992px) {
	.container {
		max-width: 960px;
	}
}
@media (min-width: 1200px) {
	.container {
		max-width: 1140px;
	}
}
.container-fluid {
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
}
.row {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: -15px;
	margin-left: -15px;
}
.no-gutters {
	margin-right: 0;
	margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*="col-"] {
	padding-right: 0;
	padding-left: 0;
}
.col,
.col-sm,
.col-md-3,
.col-md-4,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-10,
.col-md-12,
.col-md,
.col-lg-3,
.col-lg-5,
.col-lg-6,
.col-lg-7 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col {
	-ms-flex-preferred-size: 0;
	flex-basis: 0;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	max-width: 100%;
}
.order-first {
	-webkit-box-ordinal-group: 0;
	-ms-flex-order: -1;
	order: -1;
}
.order-last {
	-webkit-box-ordinal-group: 14;
	-ms-flex-order: 13;
	order: 13;
}
@media (min-width: 576px) {
	.col-sm {
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		-webkit-box-flex: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		max-width: 100%;
	}
}
@media (min-width: 768px) {
	.col-md {
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		-webkit-box-flex: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		max-width: 100%;
	}
	.col-md-3 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%;
	}
	.col-md-4 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 33.33333%;
		flex: 0 0 33.33333%;
		max-width: 33.33333%;
	}
	.col-md-6 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}
	.col-md-7 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 58.33333%;
		flex: 0 0 58.33333%;
		max-width: 58.33333%;
	}
	.col-md-8 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 66.66667%;
		flex: 0 0 66.66667%;
		max-width: 66.66667%;
	}
	.col-md-10 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 83.33333%;
		flex: 0 0 83.33333%;
		max-width: 83.33333%;
	}
	.col-md-12 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
	.order-md-last {
		-webkit-box-ordinal-group: 14;
		-ms-flex-order: 13;
		order: 13;
	}
}
@media (min-width: 992px) {
	.col-lg-3 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%;
	}
	.col-lg-5 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 41.66667%;
		flex: 0 0 41.66667%;
		max-width: 41.66667%;
	}
	.col-lg-6 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}
	.col-lg-7 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 58.33333%;
		flex: 0 0 58.33333%;
		max-width: 58.33333%;
	}
}
.table {
	width: 100%;
	margin-bottom: 1rem;
	color: #212529;
}
.table th,
.table td {
	padding: 0.75rem;
	vertical-align: top;
	border-top: 1px solid #dee2e6;
}
.table thead th {
	vertical-align: bottom;
	border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
	border-top: 2px solid #dee2e6;
}
.table-responsive {
	display: block;
	width: 100%;
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
}
.form-control {
	display: block;
	width: 100%;
	height: calc(1.5em + 0.75rem + 2px);
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
	-webkit-transition: border-color 0.15s ease-in-out,
		-webkit-box-shadow 0.15s ease-in-out;
	transition: border-color 0.15s ease-in-out,
		-webkit-box-shadow 0.15s ease-in-out;
	-o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
		-webkit-box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
	.form-control {
		-webkit-transition: none;
		-o-transition: none;
		transition: none;
	}
}
.form-control::-ms-expand {
	background-color: transparent;
	border: 0;
}
.form-control:focus {
	color: #495057;
	background-color: #fff;
	border-color: #80bdff;
	outline: 0;
	-webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
	box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.form-control::-webkit-input-placeholder {
	color: #6c757d;
	opacity: 1;
}
.form-control:-ms-input-placeholder {
	color: #6c757d;
	opacity: 1;
}
.form-control::-ms-input-placeholder {
	color: #6c757d;
	opacity: 1;
}
.form-control::placeholder {
	color: #6c757d;
	opacity: 1;
}
.form-control:disabled,
.form-control[readonly] {
	background-color: #e9ecef;
	opacity: 1;
}
select.form-control:focus::-ms-value {
	color: #495057;
	background-color: #fff;
}
select.form-control[size],
select.form-control[multiple] {
	height: auto;
}
textarea.form-control {
	height: auto;
}
.form-group {
	margin-bottom: 1rem;
}
.btn {
	display: inline-block;
	font-weight: 400;
	color: #212529;
	text-align: center;
	vertical-align: middle;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	background-color: transparent;
	border: 1px solid transparent;
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	line-height: 1.5;
	border-radius: 0.25rem;
	-webkit-transition: color 0.15s ease-in-out,
		background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
		-webkit-box-shadow 0.15s ease-in-out;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
	-o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
		-webkit-box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
	.btn {
		-webkit-transition: none;
		-o-transition: none;
		transition: none;
	}
}
.btn:hover {
	color: #212529;
	text-decoration: none;
}
.btn:focus,
.btn.focus {
	outline: 0;
	-webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
	box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.btn.disabled,
.btn:disabled {
	opacity: 0.65;
}
a.btn.disabled,
fieldset:disabled a.btn {
	pointer-events: none;
}
.btn-primary {
	color: #fff;
	background-color: #007bff;
	border-color: #007bff;
}
.btn-primary:hover {
	color: #fff;
	background-color: #0069d9;
	border-color: #0062cc;
}
.btn-primary:focus,
.btn-primary.focus {
	-webkit-box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
	box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.btn-primary.disabled,
.btn-primary:disabled {
	color: #fff;
	background-color: #007bff;
	border-color: #007bff;
}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
	color: #fff;
	background-color: #0062cc;
	border-color: #005cbf;
}
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
	-webkit-box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
	box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.fade {
	-webkit-transition: opacity 0.15s linear;
	-o-transition: opacity 0.15s linear;
	transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
	.fade {
		-webkit-transition: none;
		-o-transition: none;
		transition: none;
	}
}
.fade:not(.show) {
	opacity: 0;
}
.collapse:not(.show) {
	display: none;
}
.collapsing {
	position: relative;
	height: 0;
	overflow: hidden;
	-webkit-transition: height 0.35s ease;
	-o-transition: height 0.35s ease;
	transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
	.collapsing {
		-webkit-transition: none;
		-o-transition: none;
		transition: none;
	}
}
.dropup,
.dropright,
.dropdown,
.dropleft {
	position: relative;
}
.dropdown-toggle {
	white-space: nowrap;
}
.dropdown-toggle::after {
	display: inline-block;
	margin-left: 0.255em;
	vertical-align: 0.255em;
	content: "";
	border-top: 0.3em solid;
	border-right: 0.3em solid transparent;
	border-bottom: 0;
	border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
	margin-left: 0;
}
.dropdown-menu {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 1000;
	display: none;
	float: left;
	min-width: 10rem;
	padding: 0.5rem 0;
	margin: 0.125rem 0 0;
	font-size: 1rem;
	color: #212529;
	text-align: left;
	list-style: none;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-radius: 0.25rem;
}
.dropdown-menu-right {
	right: 0;
	left: auto;
}
.dropup .dropdown-menu {
	top: auto;
	bottom: 100%;
	margin-top: 0;
	margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
	display: inline-block;
	margin-left: 0.255em;
	vertical-align: 0.255em;
	content: "";
	border-top: 0;
	border-right: 0.3em solid transparent;
	border-bottom: 0.3em solid;
	border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
	margin-left: 0;
}
.dropright .dropdown-menu {
	top: 0;
	right: auto;
	left: 100%;
	margin-top: 0;
	margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
	display: inline-block;
	margin-left: 0.255em;
	vertical-align: 0.255em;
	content: "";
	border-top: 0.3em solid transparent;
	border-right: 0;
	border-bottom: 0.3em solid transparent;
	border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
	margin-left: 0;
}
.dropright .dropdown-toggle::after {
	vertical-align: 0;
}
.dropleft .dropdown-menu {
	top: 0;
	right: 100%;
	left: auto;
	margin-top: 0;
	margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
	display: inline-block;
	margin-left: 0.255em;
	vertical-align: 0.255em;
	content: "";
}
.dropleft .dropdown-toggle::after {
	display: none;
}
.dropleft .dropdown-toggle::before {
	display: inline-block;
	margin-right: 0.255em;
	vertical-align: 0.255em;
	content: "";
	border-top: 0.3em solid transparent;
	border-right: 0.3em solid;
	border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
	margin-left: 0;
}
.dropleft .dropdown-toggle::before {
	vertical-align: 0;
}
.dropdown-menu[x-placement^="top"],
.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="bottom"],
.dropdown-menu[x-placement^="left"] {
	right: auto;
	bottom: auto;
}
.dropdown-item {
	display: block;
	width: 100%;
	padding: 0.25rem 1.5rem;
	clear: both;
	font-weight: 400;
	color: #212529;
	text-align: inherit;
	white-space: nowrap;
	background-color: transparent;
	border: 0;
}
.dropdown-item:hover,
.dropdown-item:focus {
	color: #16181b;
	text-decoration: none;
	background-color: #f8f9fa;
}
.dropdown-item.active,
.dropdown-item:active {
	color: #fff;
	text-decoration: none;
	background-color: #007bff;
}
.dropdown-item.disabled,
.dropdown-item:disabled {
	color: #6c757d;
	pointer-events: none;
	background-color: transparent;
}
.dropdown-menu.show {
	display: block;
}
.nav {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
}
.nav-link {
	display: block;
	padding: 0.5rem 1rem;
}
.nav-link:hover,
.nav-link:focus {
	text-decoration: none;
}
.nav-link.disabled {
	color: #6c757d;
	pointer-events: none;
	cursor: default;
}
.nav-pills .nav-link {
	border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
	color: #fff;
	background-color: #007bff;
}
.tab-content > .tab-pane {
	display: none;
}
.tab-content > .active {
	display: block;
}
.navbar {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding: 0.5rem 1rem;
}
.navbar > .container,
.navbar > .container-fluid {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
.navbar-brand {
	display: inline-block;
	padding-top: 0.3125rem;
	padding-bottom: 0.3125rem;
	margin-right: 1rem;
	font-size: 1.25rem;
	line-height: inherit;
	white-space: nowrap;
}
.navbar-brand:hover,
.navbar-brand:focus {
	text-decoration: none;
}
.navbar-nav {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
}
.navbar-nav .nav-link {
	padding-right: 0;
	padding-left: 0;
}
.navbar-nav .dropdown-menu {
	position: static;
	float: none;
}
.navbar-collapse {
	-ms-flex-preferred-size: 100%;
	flex-basis: 100%;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.navbar-toggler {
	padding: 0.25rem 0.75rem;
	font-size: 1.25rem;
	line-height: 1;
	background-color: transparent;
	border: 1px solid transparent;
	border-radius: 0.25rem;
}
.navbar-toggler:hover,
.navbar-toggler:focus {
	text-decoration: none;
}
@media (max-width: 991.98px) {
	.navbar-expand-lg > .container,
	.navbar-expand-lg > .container-fluid {
		padding-right: 0;
		padding-left: 0;
	}
}
@media (min-width: 992px) {
	.navbar-expand-lg {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-flow: row nowrap;
		flex-flow: row nowrap;
		-webkit-box-pack: start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}
	.navbar-expand-lg .navbar-nav {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
	}
	.navbar-expand-lg .navbar-nav .dropdown-menu {
		position: absolute;
	}
	.navbar-expand-lg .navbar-nav .nav-link {
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}
	.navbar-expand-lg > .container,
	.navbar-expand-lg > .container-fluid {
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}
	.navbar-expand-lg .navbar-collapse {
		display: -webkit-box !important;
		display: -ms-flexbox !important;
		display: flex !important;
		-ms-flex-preferred-size: auto;
		flex-basis: auto;
	}
	.navbar-expand-lg .navbar-toggler {
		display: none;
	}
}
.navbar-dark .navbar-brand {
	color: #fff;
}
.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
	color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
	color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
	color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
	color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
	color: #fff;
}
.navbar-dark .navbar-toggler {
	color: rgba(255, 255, 255, 0.5);
	border-color: rgba(255, 255, 255, 0.1);
}
.card {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid rgba(0, 0, 0, 0.125);
	border-radius: 0.25rem;
}
.card > hr {
	margin-right: 0;
	margin-left: 0;
}
.card > .list-group:first-child .list-group-item:first-child {
	border-top-left-radius: 0.25rem;
	border-top-right-radius: 0.25rem;
}
.card > .list-group:last-child .list-group-item:last-child {
	border-bottom-right-radius: 0.25rem;
	border-bottom-left-radius: 0.25rem;
}
.card-body {
	-webkit-box-flex: 1;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	padding: 1.25rem;
}
.card-link:hover {
	text-decoration: none;
}
.card-link + .card-link {
	margin-left: 1.25rem;
}
.card-header {
	padding: 0.75rem 1.25rem;
	margin-bottom: 0;
	background-color: rgba(0, 0, 0, 0.03);
	border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
	border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.card-header + .list-group .list-group-item:first-child {
	border-top: 0;
}
.accordion > .card {
	overflow: hidden;
}
.accordion > .card:not(:first-of-type) .card-header:first-child {
	border-radius: 0;
}
.accordion > .card:not(:first-of-type):not(:last-of-type) {
	border-bottom: 0;
	border-radius: 0;
}
.accordion > .card:first-of-type {
	border-bottom: 0;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}
.accordion > .card:last-of-type {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}
.accordion > .card .card-header {
	margin-bottom: -1px;
}
.alert {
	position: relative;
	padding: 0.75rem 1.25rem;
	margin-bottom: 1rem;
	border: 1px solid transparent;
	border-radius: 0.25rem;
}
@-webkit-keyframes progress-bar-stripes {
	from {
		background-position: 1rem 0;
	}
	to {
		background-position: 0 0;
	}
}
@keyframes progress-bar-stripes {
	from {
		background-position: 1rem 0;
	}
	to {
		background-position: 0 0;
	}
}
.progress {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	height: 1rem;
	overflow: hidden;
	font-size: 0.75rem;
	background-color: #e9ecef;
	border-radius: 0.25rem;
}
.media {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
}
.media-body {
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
}
.list-group {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	padding-left: 0;
	margin-bottom: 0;
}
.list-group-item {
	position: relative;
	display: block;
	padding: 0.75rem 1.25rem;
	margin-bottom: -1px;
	background-color: #fff;
	border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
	border-top-left-radius: 0.25rem;
	border-top-right-radius: 0.25rem;
}
.list-group-item:last-child {
	margin-bottom: 0;
	border-bottom-right-radius: 0.25rem;
	border-bottom-left-radius: 0.25rem;
}
.list-group-item.disabled,
.list-group-item:disabled {
	color: #6c757d;
	pointer-events: none;
	background-color: #fff;
}
.list-group-item.active {
	z-index: 2;
	color: #fff;
	background-color: #007bff;
	border-color: #007bff;
}
.close {
	float: right;
	font-size: 1.5rem;
	font-weight: 700;
	line-height: 1;
	color: #000;
	text-shadow: 0 1px 0 #fff;
	opacity: 0.5;
}
.close:hover {
	color: #000;
	text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
	opacity: 0.75;
}
button.close {
	padding: 0;
	background-color: transparent;
	border: 0;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}
a.close.disabled {
	pointer-events: none;
}
.toast {
	max-width: 350px;
	overflow: hidden;
	font-size: 0.875rem;
	background-color: rgba(255, 255, 255, 0.85);
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, 0.1);
	-webkit-box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
	box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
	-webkit-backdrop-filter: blur(10px);
	backdrop-filter: blur(10px);
	opacity: 0;
	border-radius: 0.25rem;
}
.toast:not(:last-child) {
	margin-bottom: 0.75rem;
}
.toast.showing {
	opacity: 1;
}
.toast.show {
	display: block;
	opacity: 1;
}
.toast.hide {
	display: none;
}
.modal-open {
	overflow: hidden;
}
.modal-open .modal {
	overflow-x: hidden;
	overflow-y: auto;
}
.modal {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1050;
	display: none;
	width: 100%;
	height: 100%;
	overflow: hidden;
	outline: 0;
}
.modal-dialog {
	position: relative;
	width: auto;
	margin: 0.5rem;
	pointer-events: none;
}
.modal.fade .modal-dialog {
	-webkit-transition: -webkit-transform 0.3s ease-out;
	transition: -webkit-transform 0.3s ease-out;
	-o-transition: transform 0.3s ease-out;
	transition: transform 0.3s ease-out;
	transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
	-webkit-transform: translate(0, -50px);
	-ms-transform: translate(0, -50px);
	transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
	.modal.fade .modal-dialog {
		-webkit-transition: none;
		-o-transition: none;
		transition: none;
	}
}
.modal.show .modal-dialog {
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}
.modal-dialog-scrollable {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-body {
	overflow-y: auto;
}
.modal-backdrop {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1040;
	width: 100vw;
	height: 100vh;
	background-color: #000;
}
.modal-backdrop.fade {
	opacity: 0;
}
.modal-backdrop.show {
	opacity: 0.5;
}
.modal-body {
	position: relative;
	-webkit-box-flex: 1;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	padding: 1rem;
}
.modal-scrollbar-measure {
	position: absolute;
	top: -9999px;
	width: 50px;
	height: 50px;
	overflow: scroll;
}
@media (min-width: 576px) {
	.modal-dialog {
		max-width: 500px;
		margin: 1.75rem auto;
	}
	.modal-dialog-scrollable {
		max-height: calc(100% - 3.5rem);
	}
}
.tooltip {
	position: absolute;
	z-index: 1070;
	display: block;
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
		"Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
		"Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	font-style: normal;
	font-weight: 400;
	line-height: 1.5;
	text-align: left;
	text-align: start;
	text-decoration: none;
	text-shadow: none;
	text-transform: none;
	letter-spacing: normal;
	word-break: normal;
	word-spacing: normal;
	white-space: normal;
	line-break: auto;
	font-size: 0.875rem;
	word-wrap: break-word;
	opacity: 0;
}
.tooltip.show {
	opacity: 0.9;
}
.tooltip .arrow {
	position: absolute;
	display: block;
	width: 0.8rem;
	height: 0.4rem;
}
.tooltip .arrow::before {
	position: absolute;
	content: "";
	border-color: transparent;
	border-style: solid;
}
.tooltip-inner {
	max-width: 200px;
	padding: 0.25rem 0.5rem;
	color: #fff;
	text-align: center;
	background-color: #000;
	border-radius: 0.25rem;
}
.popover {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1060;
	display: block;
	max-width: 276px;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
		"Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
		"Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	font-style: normal;
	font-weight: 400;
	line-height: 1.5;
	text-align: left;
	text-align: start;
	text-decoration: none;
	text-shadow: none;
	text-transform: none;
	letter-spacing: normal;
	word-break: normal;
	word-spacing: normal;
	white-space: normal;
	line-break: auto;
	font-size: 0.875rem;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 0.3rem;
}
.popover .arrow {
	position: absolute;
	display: block;
	width: 1rem;
	height: 0.5rem;
	margin: 0 0.3rem;
}
.popover .arrow::before,
.popover .arrow::after {
	position: absolute;
	display: block;
	content: "";
	border-color: transparent;
	border-style: solid;
}
.popover-header {
	padding: 0.5rem 0.75rem;
	margin-bottom: 0;
	font-size: 1rem;
	background-color: #f7f7f7;
	border-bottom: 1px solid #ebebeb;
	border-top-left-radius: calc(0.3rem - 1px);
	border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
	display: none;
}
.popover-body {
	padding: 0.5rem 0.75rem;
	color: #212529;
}
.carousel {
	position: relative;
}
.carousel.pointer-event {
	-ms-touch-action: pan-y;
	touch-action: pan-y;
}
.carousel-item {
	position: relative;
	display: none;
	float: left;
	width: 100%;
	margin-right: -100%;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transition: -webkit-transform 0.6s ease-in-out;
	transition: -webkit-transform 0.6s ease-in-out;
	-o-transition: transform 0.6s ease-in-out;
	transition: transform 0.6s ease-in-out;
	transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
	.carousel-item {
		-webkit-transition: none;
		-o-transition: none;
		transition: none;
	}
}
.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
	display: block;
}
.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
	-webkit-transform: translateX(100%);
	-ms-transform: translateX(100%);
	transform: translateX(100%);
}
.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
	-webkit-transform: translateX(-100%);
	-ms-transform: translateX(-100%);
	transform: translateX(-100%);
}
.carousel-indicators {
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 15;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	padding-left: 0;
	margin-right: 15%;
	margin-left: 15%;
	list-style: none;
}
.carousel-indicators li {
	-webkit-box-sizing: content-box;
	box-sizing: content-box;
	-webkit-box-flex: 0;
	-ms-flex: 0 1 auto;
	flex: 0 1 auto;
	width: 30px;
	height: 3px;
	margin-right: 3px;
	margin-left: 3px;
	text-indent: -999px;
	cursor: pointer;
	background-color: #fff;
	background-clip: padding-box;
	border-top: 10px solid transparent;
	border-bottom: 10px solid transparent;
	opacity: 0.5;
	-webkit-transition: opacity 0.6s ease;
	-o-transition: opacity 0.6s ease;
	transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
	.carousel-indicators li {
		-webkit-transition: none;
		-o-transition: none;
		transition: none;
	}
}
.carousel-indicators .active {
	opacity: 1;
}
@-webkit-keyframes spinner-border {
	to {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes spinner-border {
	to {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes spinner-grow {
	0% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	50% {
		opacity: 1;
	}
}
.bg-primary {
	background-color: #007bff !important;
}
a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
	background-color: #0062cc !important;
}
.bg-light {
	background-color: #f8f9fa !important;
}
a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
	background-color: #dae0e5 !important;
}
.bg-dark {
	background-color: #343a40 !important;
}
a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
	background-color: #1d2124 !important;
}
.border {
	border: 1px solid #dee2e6 !important;
}
.rounded-circle {
	border-radius: 50% !important;
}
.d-block {
	display: block !important;
}
.d-flex {
	display: -webkit-box !important;
	display: -ms-flexbox !important;
	display: flex !important;
}
@media (min-width: 768px) {
	.d-md-flex {
		display: -webkit-box !important;
		display: -ms-flexbox !important;
		display: flex !important;
	}
}
.justify-content-start {
	-webkit-box-pack: start !important;
	-ms-flex-pack: start !important;
	justify-content: flex-start !important;
}
.justify-content-end {
	-webkit-box-pack: end !important;
	-ms-flex-pack: end !important;
	justify-content: flex-end !important;
}
.justify-content-center {
	-webkit-box-pack: center !important;
	-ms-flex-pack: center !important;
	justify-content: center !important;
}
.align-items-end {
	-webkit-box-align: end !important;
	-ms-flex-align: end !important;
	align-items: flex-end !important;
}
.align-items-center {
	-webkit-box-align: center !important;
	-ms-flex-align: center !important;
	align-items: center !important;
}
.align-self-end {
	-ms-flex-item-align: end !important;
	align-self: flex-end !important;
}
.align-self-center {
	-ms-flex-item-align: center !important;
	-ms-grid-row-align: center !important;
	align-self: center !important;
}
.align-self-stretch {
	-ms-flex-item-align: stretch !important;
	-ms-grid-row-align: stretch !important;
	align-self: stretch !important;
}
@media (min-width: 768px) {
	.align-self-md-center {
		-ms-flex-item-align: center !important;
		-ms-grid-row-align: center !important;
		align-self: center !important;
	}
}
.position-static {
	position: static !important;
}
.fixed-top {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1030;
}
.fixed-bottom {
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1030;
}
@supports ((position: -webkit-sticky) or (position: sticky)) {
	.sticky-top {
		position: -webkit-sticky;
		position: sticky;
		top: 0;
		z-index: 1020;
	}
}
.w-100 {
	width: 100% !important;
}
.mb-0 {
	margin-bottom: 0 !important;
}
.m-1 {
	margin: 0.25rem !important;
}
.mt-2 {
	margin-top: 0.5rem !important;
}
.mr-2 {
	margin-right: 0.5rem !important;
}
.mt-3 {
	margin-top: 1rem !important;
}
.mr-3 {
	margin-right: 1rem !important;
}
.mb-3 {
	margin-bottom: 1rem !important;
}
.mr-4 {
	margin-right: 1.5rem !important;
}
.mb-4 {
	margin-bottom: 1.5rem !important;
}
.mt-5 {
	margin-top: 3rem !important;
}
.mr-5 {
	margin-right: 3rem !important;
}
.mb-5 {
	margin-bottom: 3rem !important;
}
.px-0 {
	padding-right: 0 !important;
}
.px-0 {
	padding-left: 0 !important;
}
.py-2 {
	padding-top: 0.5rem !important;
}
.py-2 {
	padding-bottom: 0.5rem !important;
}
.p-3 {
	padding: 1rem !important;
}
.py-3 {
	padding-top: 1rem !important;
}
.px-3 {
	padding-right: 1rem !important;
}
.pb-3,
.py-3 {
	padding-bottom: 1rem !important;
}
.pl-3,
.px-3 {
	padding-left: 1rem !important;
}
.p-4 {
	padding: 1.5rem !important;
}
.py-4 {
	padding-top: 1.5rem !important;
}
.px-4 {
	padding-right: 1.5rem !important;
}
.py-4 {
	padding-bottom: 1.5rem !important;
}
.pl-4,
.px-4 {
	padding-left: 1.5rem !important;
}
.p-5 {
	padding: 3rem !important;
}
.pt-5,
.py-5 {
	padding-top: 3rem !important;
}
.px-5 {
	padding-right: 3rem !important;
}
.pb-5,
.py-5 {
	padding-bottom: 3rem !important;
}
.px-5 {
	padding-left: 3rem !important;
}
.ml-auto {
	margin-left: auto !important;
}
@media (min-width: 576px) {
	.mb-sm-4 {
		margin-bottom: 1.5rem !important;
	}
	.pb-sm-1 {
		padding-bottom: 0.25rem !important;
	}
}
@media (min-width: 768px) {
	.mr-md-5 {
		margin-right: 3rem !important;
	}
	.ml-md-5 {
		margin-left: 3rem !important;
	}
	.pl-md-0 {
		padding-left: 0 !important;
	}
	.pr-md-4 {
		padding-right: 1.5rem !important;
	}
	.p-md-5 {
		padding: 3rem !important;
	}
	.py-md-5 {
		padding-top: 3rem !important;
	}
	.pr-md-5 {
		padding-right: 3rem !important;
	}
	.pb-md-5,
	.py-md-5 {
		padding-bottom: 3rem !important;
	}
}
@media (min-width: 992px) {
	.mb-lg-0 {
		margin-bottom: 0 !important;
	}
	.py-lg-5 {
		padding-top: 3rem !important;
	}
	.py-lg-5 {
		padding-bottom: 3rem !important;
	}
}
.text-center {
	text-align: center !important;
}
@media (min-width: 768px) {
	.text-md-left {
		text-align: left !important;
	}
	.text-md-right {
		text-align: right !important;
	}
}
.font-weight-bold {
	font-weight: 700 !important;
}
.visible {
	visibility: visible !important;
}
@media print {
	*,
	*::before,
	*::after {
		text-shadow: none !important;
		-webkit-box-shadow: none !important;
		box-shadow: none !important;
	}
	a:not(.btn) {
		text-decoration: underline;
	}
	pre {
		white-space: pre-wrap !important;
	}
	pre {
		border: 1px solid #adb5bd;
		page-break-inside: avoid;
	}
	thead {
		display: table-header-group;
	}
	tr,
	img {
		page-break-inside: avoid;
	}
	p,
	h2,
	h3 {
		orphans: 3;
		widows: 3;
	}
	h2,
	h3 {
		page-break-after: avoid;
	}
	@page {
		size: a3;
	}
	body {
		min-width: 992px !important;
	}
	.container {
		min-width: 992px !important;
	}
	.navbar {
		display: none;
	}
	.table {
		border-collapse: collapse !important;
	}
	.table td,
	.table th {
		background-color: #fff !important;
	}
}
body {
	font-family: "Work Sans", Arial, sans-serif;
	background: #fff;
	font-size: 16px;
	line-height: 1.8;
	font-weight: 400;
	color: #666;
}
a {
	-webkit-transition: 0.3s all ease;
	-o-transition: 0.3s all ease;
	transition: 0.3s all ease;
	color: #6927ff;
}
a:hover,
a:focus {
	text-decoration: none;
	color: #6927ff;
	outline: none !important;
}
h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
	line-height: 1.5;
	color: #000;
	font-weight: 400;
}
.ftco-navbar-light {
	background: transparent !important;
	position: absolute;
	top: 20px;
	left: 0;
	right: 0;
	z-index: 3;
}
@media (max-width: 991.98px) {
	.ftco-navbar-light {
		background: #000 !important;
		position: relative;
		top: 0;
	}
}
.ftco-navbar-light .navbar-brand {
	color: #000;
}
.ftco-navbar-light .navbar-brand:hover {
	color: #000;
}
@media (max-width: 991.98px) {
	.ftco-navbar-light .navbar-brand {
		color: #fff;
	}
	.ftco-navbar-light .navbar-nav {
		padding-bottom: 10px;
	}
}
.ftco-navbar-light .navbar-nav > .nav-item > .nav-link {
	font-size: 13px;
	padding-top: 0.9rem;
	padding-bottom: 0.9rem;
	padding-left: 20px;
	padding-right: 20px;
	letter-spacing: 1px;
	color: #000;
	font-weight: 500;
	text-transform: uppercase;
	opacity: 1 !important;
}
.ftco-navbar-light .navbar-nav > .nav-item > .nav-link:hover {
	color: #6927ff;
}
@media (max-width: 991.98px) {
	.ftco-navbar-light .navbar-nav > .nav-item > .nav-link {
		padding-left: 0;
		padding-right: 0;
		padding-top: 0.5em;
		padding-bottom: 0.5em;
		color: rgba(255, 255, 255, 0.7);
	}
	.ftco-navbar-light .navbar-nav > .nav-item > .nav-link:hover {
		color: #fff;
	}
}
.ftco-navbar-light .navbar-nav > .nav-item .dropdown-menu {
	border: none;
	background: #fff;
	-webkit-box-shadow: 0 10px 34px -20px rgba(0, 0, 0, 0.41);
	-moz-box-shadow: 0 10px 34px -20px rgba(0, 0, 0, 0.41);
	box-shadow: 0 10px 34px -20px rgba(0, 0, 0, 0.41);
}
.ftco-navbar-light .navbar-nav > .nav-item.cta > a {
	color: #fff;
	border: 1px solid #6927ff;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	padding-left: 20px;
	padding-right: 20px;
	margin-top: 4px;
	background: #6927ff;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	border-radius: 5px;
}
.ftco-navbar-light .navbar-nav > .nav-item.cta > a span {
	display: inline-block;
	color: #fff;
}
.ftco-navbar-light .navbar-nav > .nav-item.cta > a:hover {
	color: #fff;
	background: #6927ff;
	border: 1px solid #6927ff;
}
.ftco-navbar-light .navbar-nav > .nav-item.active > a {
	color: rgba(255, 255, 255, 0.5);
	color: #6927ff;
}
.ftco-navbar-light .navbar-toggler {
	border: none;
	color: rgba(255, 255, 255, 0.5) !important;
	cursor: pointer;
	padding-right: 0;
	text-transform: uppercase;
	font-size: 16px;
	letter-spacing: 0.1em;
}
.ftco-navbar-light .navbar-toggler:hover,
.ftco-navbar-light .navbar-toggler:focus {
	text-decoration: none;
	color: #6927ff;
	outline: none !important;
}
.ftco-navbar-light.scrolled {
	position: fixed;
	right: 0;
	left: 0;
	top: 0;
	margin-top: -130px;
	background: #fff !important;
	-webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}
@media (max-width: 991.98px) {
	.ftco-navbar-light.scrolled .nav-item > .nav-link {
		padding-left: 0 !important;
		padding-right: 0 !important;
		padding-bottom: 0 !important;
	}
}
.ftco-navbar-light.scrolled .nav-item.active > a {
	color: #6927ff !important;
}
.ftco-navbar-light.scrolled .nav-item.cta > a {
	color: #fff !important;
	background: #6927ff;
	border: none !important;
	padding-top: 0.5rem !important;
	padding-bottom: 0.5rem !important;
	padding-left: 20px !important;
	padding-right: 20px !important;
	margin-top: 6px !important;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	border-radius: 5px;
}
.ftco-navbar-light.scrolled .nav-item.cta > a span {
	display: inline-block;
	color: #fff !important;
}
@media (max-width: 991.98px) {
	.ftco-navbar-light.scrolled .navbar-nav {
		background: none;
		border-radius: 0;
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
}
.ftco-navbar-light.scrolled .navbar-toggler {
	border: none;
	color: rgba(0, 0, 0, 0.5) !important;
	border-color: rgba(0, 0, 0, 0.5) !important;
	cursor: pointer;
	padding-right: 0;
	text-transform: uppercase;
	font-size: 16px;
	letter-spacing: 0.1em;
}
.ftco-navbar-light.scrolled .nav-link {
	padding-top: 0.9rem !important;
	padding-bottom: 0.9rem !important;
	color: #000 !important;
}
.ftco-navbar-light.scrolled .nav-link.active {
	color: #6927ff !important;
}
.ftco-navbar-light.scrolled.awake {
	margin-top: 0;
	-webkit-transition: 0.3s all ease-out;
	-o-transition: 0.3s all ease-out;
	transition: 0.3s all ease-out;
}
.ftco-navbar-light.scrolled.sleep {
	-webkit-transition: 0.3s all ease-out;
	-o-transition: 0.3s all ease-out;
	transition: 0.3s all ease-out;
}
.ftco-navbar-light.scrolled .navbar-brand {
	color: #000;
}
.navbar-brand {
	font-size: 20px;
	font-weight: 900;
}
.hero-wrap {
	width: 100%;
	height: 100%;
	position: inherit;
}
.hero-wrap .overlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	content: "";
	opacity: 0;
	z-index: -1;
}
.hero-wrap .slider-text {
	height: 750px;
	z-index: 0;
	overflow: hidden;
}
@media (max-width: 991.98px) {
	.hero-wrap .slider-text {
		text-align: center;
		height: 750px;
	}
}
.hero-wrap .slider-text .one-third {
	width: 60%;
	position: relative;
	z-index: -1;
}
.hero-wrap .slider-text .one-third .overlay {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: #000;
	opacity: 0;
}
@media (max-width: 1199.98px) {
	.hero-wrap .slider-text .one-third {
		width: 100%;
		z-index: 0;
	}
	.hero-wrap .slider-text .one-third .overlay {
		opacity: 0.3;
	}
	.hero-wrap .slider-text .one-third:after {
		opacity: 0;
	}
}
.hero-wrap .slider-text .one-forth {
	position: relative;
	height: 750px;
}
@media (min-width: 992px) {
	.hero-wrap .slider-text .one-forth {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		width: 1200px;
		margin: 0 auto;
	}
}
@media (max-width: 1199.98px) {
	.hero-wrap .slider-text .one-forth {
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
		padding: 1em;
	}
}
@media (max-width: 991.98px) {
	.hero-wrap .slider-text .one-forth {
		height: 600px;
	}
}
.hero-wrap .slider-text .one-forth .text {
	position: relative;
}
@media (min-width: 992px) {
	.hero-wrap .slider-text .one-forth .text {
		position: absolute;
		top: 50%;
		left: 0;
		-webkit-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
		right: 0;
		max-width: 40%;
		padding-left: 45px;
		padding-right: 3em;
	}
}
.hero-wrap .slider-text .subheading {
	color: #000;
	font-weight: 700;
	font-size: 14px;
	letter-spacing: 4px;
	text-transform: uppercase;
	display: inline-block;
	color: #6927ff;
}
.hero-wrap .slider-text .text {
	position: relative;
	z-index: 1;
}
.hero-wrap .slider-text h1 {
	font-size: 60px;
	color: #000;
	line-height: 1.2;
	font-weight: 700;
}
.hero-wrap .slider-text h1 span {
	display: block;
	font-weight: 700;
}
@media (max-width: 991.98px) {
	.hero-wrap .slider-text h1 {
		font-size: 50px;
	}
}
@media (max-width: 767.98px) {
	.hero-wrap .slider-text h1 {
		font-size: 40px;
	}
}
.hero-wrap .slider-text p {
	color: rgba(0, 0, 0, 0.8);
	font-weight: 400;
}
@media (max-width: 991.98px) {
	.hero-wrap .slider-text p {
		color: rgba(0, 0, 0, 0.7);
		font-size: 20px;
	}
}
.hero-wrap .slider-text p .number {
	color: #38d39f;
}
.hero-wrap.hero-wrap-2 {
	height: 600px !important;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: top center;
}
@media (max-width: 1199.98px) {
	.hero-wrap.hero-wrap-2 {
		background-position: top center !important;
	}
}
.hero-wrap.hero-wrap-2 .slider-text {
	height: 600px !important;
}
@media (max-width: 991.98px) {
	.hero-wrap.hero-wrap-2 .slider-text {
		text-align: center;
		height: 600px !important;
	}
}
.slider-text .breadcrumbs {
	font-size: 12px;
	letter-spacing: 1px;
	margin-bottom: 20px;
	z-index: 99;
	text-transform: uppercase;
	font-weight: 700 !important;
	letter-spacing: 2px;
}
.slider-text .breadcrumbs span {
	color: rgba(0, 0, 0, 0.7);
}
.slider-text .breadcrumbs span a {
	color: #000;
}
.slider-text .bread {
	font-weight: 900;
	color: #000 !important;
}
.bg-light {
	background: #fafafa !important;
	z-index: 0;
}
.bg-primary {
	background: #6927ff;
}
.btn {
	cursor: pointer;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	-ms-border-radius: 30px;
	border-radius: 30px;
	-webkit-box-shadow: 0 15px 30px -12px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0 15px 30px -12px rgba(0, 0, 0, 0.2);
	-ms-box-shadow: 0 15px 30px -12px rgba(0, 0, 0, 0.2);
	-o-box-shadow: 0 15px 30px -12px rgba(0, 0, 0, 0.2);
	box-shadow: 0 15px 30px -12px rgba(0, 0, 0, 0.2);
}
.btn:hover,
.btn:active,
.btn:focus {
	outline: none;
}
.btn.btn-primary {
	background: #6927ff;
	border: 1px solid #6927ff;
	color: #fff;
}
.btn.btn-primary:hover {
	border: 1px solid #6927ff;
	background: transparent;
	color: #6927ff;
}
.heading-white {
	color: #fff;
}
.heading-white h2 {
	font-size: 24px;
	color: #000;
	margin-bottom: 0;
	font-weight: 700;
}
.heading-white p {
	color: rgba(0, 0, 0, 0.7);
	margin-bottom: 0;
}
.ftco-wrap {
	background: #6927ff;
	border-radius: 50px 0 50px 0;
}
.domain-form {
	width: 100%;
	display: block;
}
.domain-form .domain-name {
	width: calc(100% - 300px);
}
@media (max-width: 767.98px) {
	.domain-form .domain-name {
		width: 100%;
	}
}
.domain-form .domain-select {
	width: 300px;
}
@media (max-width: 767.98px) {
	.domain-form .domain-select {
		width: 100%;
	}
}
.domain-form .form-group {
	position: relative;
	margin-bottom: 0;
}
.domain-form .form-group select {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border: 1px solid #e6e6e6 !important;
	border-right: none !important;
}
.domain-form .form-group .form-control {
	height: 60px !important;
}
.domain-form .form-group input,
.domain-form .form-group select {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	font-size: 14px;
	padding-right: 20px !important;
	color: #000;
	border: 1px solid #e6e6e6;
	border-right: none;
}
.domain-form .form-group input.name,
.domain-form .form-group select.name {
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}
.domain-form .form-group input option,
.domain-form .form-group select option {
	color: #000;
}
.domain-form .form-group input::-webkit-input-placeholder,
.domain-form .form-group select::-webkit-input-placeholder {
	color: #000 !important;
}
.domain-form .form-group input::-moz-placeholder,
.domain-form .form-group select::-moz-placeholder {
	color: #000 !important;
}
.domain-form .form-group input:-ms-input-placeholder,
.domain-form .form-group select:-ms-input-placeholder {
	color: #000 !important;
}
.domain-form .form-group input:-moz-placeholder,
.domain-form .form-group select:-moz-placeholder {
	color: #000 !important;
}
.domain-form .form-group .btn-primary {
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	-ms-border-radius: 0;
	border-radius: 0;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
	color: #fff;
	text-align: center;
	background: #38d39f !important;
	border: none;
}
.domain-form .select-wrap {
	position: relative;
	width: 80px;
}
.domain-form .search-domain {
	width: calc(100% - 80px);
}
@media (max-width: 991.98px) {
	.domain-form .search-domain {
		width: calc(100% - 80px);
	}
}
.domain-form .icon {
	position: absolute;
	top: 50% !important;
	right: 15px;
	font-size: 14px;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	color: #000;
}
@media (max-width: 767.98px) {
	.domain-form .icon {
		right: 15px;
	}
}
.domain-price {
	margin-bottom: 0;
}
.domain-price span {
	color: #38d39f;
	margin: 0 0 5px 0;
	font-weight: 500;
	border: 1px solid rgba(255, 255, 255, 0.1);
	padding: 5px 10px;
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	-ms-border-radius: 20px;
	border-radius: 20px;
}
.domain-price span small {
	color: #fff;
}
.ftco-wrap-2 {
	background: #fff;
}
.ftco-wrap-2 .domain-price span small {
	color: #000;
}
.table {
	text-align: center;
}
.table th {
	font-weight: 500;
}
.table .thead-primary tr th {
	padding: 30px 10px;
	color: #fff !important;
	border-bottom-color: currentColor;
	/* border: 1px solid transparent !important;
	background: #38d39f; */
}
.table tbody tr td {
	vertical-align: middle;
	font-weight: 500;
	padding: 20px 10px !important;
	color: #000;
	/* border: 1px solid transparent !important;
	border-bottom: 1px solid rgba(0, 0, 0, 0.05) !important; */
}
@media (min-width: 768px) {
	.table tbody tr td:nth-child(odd) {
		/* background: #f0f0f0; */
	}
}
.table tbody tr td.color {
	color: #fff;
	background: #6927ff;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
}
#accordion .card {
	margin-bottom: 5px;
	border: 1px solid #f0f0f0;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	-ms-border-radius: 0;
	border-radius: 0;
}
#accordion .card .card-header {
	padding: 0;
	background: transparent;
	border-bottom: 1px solid #f0f0f0;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	-ms-border-radius: 0;
	border-radius: 0;
}
#accordion .card .card-header a {
	padding: 12px 20px;
	display: block;
	font-size: 20px;
	font-weight: 500;
	color: #000;
}
#accordion .card .card-header a span {
	float: right;
}
#accordion .card .card-header a span i {
	color: #38d39f;
}
#accordion .card-body {
	border: 1px solid transparent;
}
#accordion .card-body p {
	color: #4d4d4d !important;
}
#accordion [aria-expanded="false"] > .expanded,
#accordion [aria-expanded="true"] > .collapsed {
	display: none;
}
.services {
	width: 100%;
	display: block;
	padding: 30px;
	background: #fff;
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	transition: all 0.3s ease;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	-ms-border-radius: 4px;
	border-radius: 4px;
}
.services.border {
	border: 1px solid rgba(0, 0, 0, 0.1) !important;
}
.services .icon {
	line-height: 1.3;
	position: relative;
	width: 80px;
	height: 80px;
	margin: 0 auto;
	background: #6927ff;
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	transition: all 0.3s ease;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	border-radius: 50%;
}
.services .icon span {
	font-size: 44px;
	color: #fff;
}
.services .media-body {
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	transition: all 0.3s ease;
	width: calc(100% - 80px);
}
.services .media-body h3 {
	font-size: 20px;
	font-weight: 700;
}
.services .media-body.media-body-2 {
	width: 100% !important;
}
.services:hover {
	background: #6927ff;
}
.services:hover .icon {
	background: #fff;
}
.services:hover .icon span {
	color: #6927ff;
}
.services:hover .media-body h3 {
	color: #fff;
}
.services:hover .media-body p {
	color: rgba(255, 255, 255, 0.8);
}
.steps h3 {
	font-size: 22px;
	font-weight: 500;
}
.steps .icon {
	width: 100px;
	height: 100px;
	margin: 0 auto;
	background: #6927ff;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	border-radius: 50%;
}
.steps .icon span {
	font-size: 40px;
	color: #fff;
}
.ftco-partner {
	padding: 5em 0;
}
.ftco-partner .partner {
	display: block;
	padding: 0 20px;
}
@media (max-width: 991.98px) {
	.ftco-partner .partner {
		padding: 0;
	}
}
.form-control {
	height: 52px;
	background: #fff !important;
	color: #000 !important;
	font-size: 18px;
	border-radius: 0;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
	border: 1px solid #ebebeb;
}
.form-control:focus,
.form-control:active {
	border-color: #000;
}
textarea.form-control {
	height: inherit !important;
}
.ftco-animate {
	opacity: 0;
	visibility: hidden;
}
.bg-primary {
	background: #6927ff !important;
}
.about-author .desc h3 {
	font-size: 20px;
	font-weight: 700;
}
.about-author .bio {
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	border-radius: 50%;
}
.ftco-section {
	padding: 6em 0;
	position: relative;
}
@media (max-width: 767.98px) {
	.ftco-section {
		padding: 6em 0;
	}
}
.ftco-no-pt {
	padding-top: 0;
}
.ftco-bg-dark {
	background: #3c312e;
}
.ftco-footer {
	font-size: 16px;
	padding: 5em 0;
	padding-bottom: 3em;
	background: #421b9b;
}
.ftco-footer .ftco-footer-widget.bg-primary {
	background: #5423c6 !important;
}
.ftco-footer .ftco-footer-widget h2 {
	font-weight: normal;
	color: #fff;
	margin-bottom: 40px;
	font-size: 20px;
	font-weight: 700;
}
.ftco-footer .ftco-footer-widget ul li a span {
	color: #fff;
}
.ftco-footer .ftco-footer-widget .btn-primary {
	border: 1px solid transparent;
}
.ftco-footer .ftco-footer-widget .btn-primary:hover {
	background: #fff;
	border: 1px solid #fff !important;
}
.ftco-footer p {
	color: rgba(255, 255, 255, 0.7);
}
.ftco-footer a {
	color: rgba(255, 255, 255, 0.7);
}
.ftco-footer a:hover {
	color: #fff;
}
.ftco-footer .ftco-heading-2 {
	font-size: 17px;
	font-weight: 400;
	color: #000;
}
.ftco-footer .price .subheading {
	color: rgba(255, 255, 255, 0.8);
}
.ftco-footer .price h3 {
	font-size: 40px;
	color: #fff;
	font-weight: 700;
}
.ftco-footer .price h3 span {
	font-size: 14px;
	font-weight: 400;
}
.ftco-footer-social li {
	list-style: none;
	margin: 0 10px 0 0;
	display: inline-block;
}
.ftco-footer-social li a {
	height: 50px;
	width: 50px;
	display: block;
	float: left;
	border-radius: 50%;
	position: relative;
}
.ftco-footer-social li a span {
	position: absolute;
	font-size: 26px;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}
.ftco-footer-social li a:hover {
	color: #fff;
}
#map {
	height: 400px;
	width: 100%;
}
@media (max-width: 991.98px) {
	#map {
		height: 300px;
	}
}
@keyframes pulse {
	0% {
		-moz-box-shadow: 0 0 0 0 rgba(105, 39, 255, 0.4);
		-webkit-box-shadow: 0 0 0 0 rgba(105, 39, 255, 0.4);
		box-shadow: 0 0 0 0 rgba(105, 39, 255, 0.4);
	}
	70% {
		-moz-box-shadow: 0 0 0 30px rgba(105, 39, 255, 0);
		-webkit-box-shadow: 0 0 0 30px rgba(105, 39, 255, 0);
		box-shadow: 0 0 0 30px rgba(105, 39, 255, 0);
	}
	100% {
		-moz-box-shadow: 0 0 0 0 rgba(105, 39, 255, 0);
		-webkit-box-shadow: 0 0 0 0 rgba(105, 39, 255, 0);
		box-shadow: 0 0 0 0 rgba(105, 39, 255, 0);
	}
}
.heading-section .subheading {
	font-size: 14px;
	font-size: 500;
	display: block;
	margin-bottom: 0;
	text-transform: uppercase;
	letter-spacing: 1px;
}
.heading-section h2 {
	font-size: 40px;
	font-weight: 700;
	color: #000;
}
@media (max-width: 767.98px) {
	.heading-section h2 {
		font-size: 30px;
	}
}
.heading-section.heading-section-white .subheading {
	color: rgba(255, 255, 255, 0.9);
}
.heading-section.heading-section-white h2 {
	font-size: 40px;
	color: #fff;
}
@media (max-width: 767.98px) {
	.heading-section.heading-section-white h2 {
		font-size: 30px;
	}
}
.heading-section.heading-section-white p {
	color: rgba(255, 255, 255, 0.9);
}
.img,
.blog-img,
.user-img {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}
.testimony-section {
	position: relative;
}
.testimony-section .owl-carousel {
	margin: 0;
}
.testimony-section .owl-carousel .owl-stage-outer {
	padding-top: 3.5em;
	padding-bottom: 2em;
	position: relative;
}
.testimony-section .owl-nav {
	position: absolute;
	top: 100%;
	width: 100%;
}
.testimony-section .owl-nav .owl-prev,
.testimony-section .owl-nav .owl-next {
	position: absolute;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	margin-top: -10px;
	outline: none !important;
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	transition: all 0.3s ease;
	opacity: 0;
}
.testimony-section .owl-nav .owl-prev span:before,
.testimony-section .owl-nav .owl-next span:before {
	font-size: 30px;
	color: rgba(255, 255, 255, 0.5);
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	transition: all 0.3s ease;
}
.testimony-section .owl-nav .owl-prev:hover span:before,
.testimony-section .owl-nav .owl-prev:focus span:before,
.testimony-section .owl-nav .owl-next:hover span:before,
.testimony-section .owl-nav .owl-next:focus span:before {
	color: #fff;
}
.testimony-section .owl-nav .owl-prev {
	left: 50%;
	margin-left: -80px;
}
.testimony-section .owl-nav .owl-next {
	right: 50%;
	margin-right: -80px;
}
.testimony-section:hover .owl-nav .owl-prev,
.testimony-section:hover .owl-nav .owl-next {
	opacity: 1;
}
.testimony-section:hover .owl-nav .owl-prev {
	left: 50%;
	margin-left: -80px;
}
.testimony-section:hover .owl-nav .owl-next {
	right: 50%;
	margin-right: -80px;
}
.testimony-section .owl-dots {
	text-align: center;
}
.testimony-section .owl-dots .owl-dot {
	width: 10px;
	height: 10px;
	margin: 5px;
	border-radius: 50%;
	background: rgba(255, 255, 255, 0.4);
}
.testimony-section .owl-dots .owl-dot.active {
	background: #fff;
}
.testimony-wrap {
	border: 1px solid rgba(0, 0, 0, 0.1);
	display: block;
	position: relative;
}
.testimony-wrap .user-img {
	width: 100px;
	height: 100px;
	border-radius: 50%;
	position: relative;
	margin: 0 auto;
}
.testimony-wrap .user-img .quote {
	position: absolute;
	bottom: -10px;
	right: 0;
	width: 40px;
	height: 40px;
	background: #38d39f;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	border-radius: 50%;
}
.testimony-wrap .user-img .quote i {
	color: #fff;
}
.testimony-wrap .name {
	font-weight: 700;
	font-size: 18px;
	margin-bottom: 0;
	color: #000;
}
.testimony-wrap .position {
	font-size: 13px;
}
@media (max-width: 767.98px) {
	.about-image {
		height: 400px;
		margin-bottom: 30px;
	}
}
.ftco-section {
	position: relative;
	width: 100%;
	display: block;
}
.ftco-section .nav-pills p {
	margin-bottom: 0;
}
.ftco-section .nav-pills .nav-link {
	border-radius: 0;
	margin-bottom: 0;
	color: #6927ff;
	font-size: 18px;
	font-weight: 600;
	position: relative;
	display: inline-block;
	border: 1px solid #d3c0ff;
	margin-left: 5px;
	margin-right: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	border-radius: 5px;
}
.ftco-section .nav-pills .nav-link.active,
.ftco-section .nav-pills .nav-link:hover {
	color: #fff;
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	transition: all 0.3s ease;
	background: #38d39f;
	border: 1px solid transparent;
}
.ftco-section .nav-pills .nav-link.active:after,
.ftco-section .nav-pills .nav-link:hover:after {
	opacity: 1;
}
@media (max-width: 767.98px) {
	.ftco-section .nav-pills .nav-link.active:after,
	.ftco-section .nav-pills .nav-link:hover:after {
		opacity: 0;
	}
	.ftco-section .nav-pills .nav-link {
		display: block;
		width: 100%;
		margin-bottom: 5px;
	}
}
.ftco-section .tab-content .tab-pane h2 {
	font-size: 24px;
	font-weight: 700;
}
.ftco-section .tab-content .tab-pane .one-forth {
	width: 50%;
}
@media (max-width: 767.98px) {
	.ftco-section .tab-content .tab-pane .one-forth img {
		margin-bottom: 20px;
	}
	.ftco-section .tab-content .tab-pane .one-forth {
		width: 100%;
	}
}
.ftco-section .tab-content .tab-pane .one-half {
	width: 50%;
}
@media (max-width: 767.98px) {
	.ftco-section .tab-content .tab-pane .one-half {
		width: 100%;
	}
}
.image-popup {
	cursor: -webkit-zoom-in;
	cursor: -moz-zoom-in;
	cursor: zoom-in;
}
.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
	opacity: 0;
	-webkit-backface-visibility: hidden;
	-webkit-transition: all 0.3s ease-out;
	-moz-transition: all 0.3s ease-out;
	-o-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
}
.mfp-with-zoom.mfp-ready .mfp-container {
	opacity: 1;
}
.mfp-with-zoom.mfp-ready.mfp-bg {
	opacity: 0.8;
}
.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
	opacity: 0;
}
#section-counter {
	position: relative;
	z-index: 0;
}
#section-counter:after {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	content: "";
	z-index: -1;
	opacity: 0.9;
}
.ftco-counter {
	padding: 5em 0;
	background: #6927ff;
}
@media (max-width: 991.98px) {
	.ftco-counter {
		background-position: center center !important;
	}
}
.ftco-counter .text strong.number {
	font-weight: 700;
	font-size: 40px;
	color: #fff;
}
.ftco-counter .text span {
	font-size: 16px;
	color: rgba(255, 255, 255, 0.7);
}
@media (max-width: 767.98px) {
	.ftco-counter .counter-wrap {
		margin-bottom: 20px;
	}
}
.block-20 {
	overflow: hidden;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	height: 275px;
	position: relative;
	display: block;
}
@media (min-width: 768px) {
	.blog-entry {
		margin-bottom: 30px;
	}
}
@media (max-width: 767.98px) {
	.blog-entry {
		margin-bottom: 30px;
	}
}
.blog-entry .text {
	position: relative;
	border-top: 0;
	border-radius: 2px;
}
.blog-entry .text .desc {
	width: calc(100% - 100px);
}
.blog-entry .text .heading {
	font-size: 20px;
	margin-bottom: 16px;
}
.blog-entry .text .heading a {
	color: #000;
}
.blog-entry .text .heading a:hover,
.blog-entry .text .heading a:focus,
.blog-entry .text .heading a:active {
	color: #6927ff;
}
.blog-entry .text .meta-chat {
	color: #b3b3b3;
}
.blog-entry .text .read {
	color: #000;
	font-size: 14px;
}
.blog-entry .meta {
	width: 100px;
	text-align: right;
}
.blog-entry .meta > div {
	display: block;
	margin-right: 5px;
	margin-bottom: 0;
	font-size: 15px;
}
.blog-entry .meta > div a {
	color: #b3b3b3;
	font-size: 13px;
}
.blog-entry .meta > div a:hover {
	color: #ccc;
}
.block-23 ul {
	padding: 0;
}
.block-23 ul li,
.block-23 ul li > a {
	display: table;
	line-height: 1.5;
	margin-bottom: 15px;
}
.block-23 ul li span {
	color: rgba(255, 255, 255, 0.7);
}
.block-23 ul li .icon,
.block-23 ul li .text {
	display: table-cell;
	vertical-align: top;
}
.block-23 ul li .icon {
	width: 40px;
	font-size: 18px;
	padding-top: 2px;
	color: #fff;
}
.block-6 {
	margin-bottom: 40px;
}
.block-6 .media-body p {
	font-size: 16px;
}
.block-18 .icon > span {
	font-size: 40px;
}
.block-18 .text strong {
	font-size: 30px;
}
.block-18 .text span {
	display: block;
}
.block-27 ul {
	padding: 0;
	margin: 0;
}
.block-27 ul li {
	display: inline-block;
	margin-bottom: 4px;
	font-weight: 400;
}
.block-27 ul li a,
.block-27 ul li span {
	color: #6927ff;
	text-align: center;
	display: inline-block;
	width: 40px;
	height: 40px;
	line-height: 40px;
	border-radius: 50%;
	border: 1px solid #e5daff;
}
.block-27 ul li.active a,
.block-27 ul li.active span {
	background: #6927ff;
	color: #fff;
	border: 1px solid transparent;
}
.block-7 {
	padding: 30px;
	background: #fff;
}
@media (max-width: 991.98px) {
	.block-7 {
		margin-bottom: 30px;
	}
}
.block-7 .heading {
	font-size: 14px;
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: 2px;
	color: rgba(0, 0, 0, 0.3);
}
.block-7 .heading-2 {
	font-size: 16px;
	font-weight: normal;
}
.block-7 .price {
	margin: 0;
	padding: 0;
	display: block;
}
.block-7 .price sup {
	font-size: 20px;
	top: -1em;
	color: #000;
}
.block-7 .price .number {
	font-size: 50px;
	font-weight: 700;
	color: #000;
}
.block-7 .price .number small {
	font-size: 12px;
}
.block-7 .excerpt {
	margin-bottom: 20px;
	color: #b3b3b3;
}
.block-7 .pricing-text {
	margin-bottom: 0;
}
.block-7 .pricing-text,
.block-7 .pricing-text li {
	padding: 0;
	margin: 0;
}
.block-7 .pricing-text li {
	list-style: none;
	margin-bottom: 10px;
	color: #b3b3b3;
}
.block-7 .pricing-text li strong {
	color: #000;
}
.block-7 .btn-primary {
	border: none;
}
.block-7 .btn-primary:hover {
	border: none;
	color: #fff;
	background: #6927ff !important;
}
.contact-section .contact-info p a {
	color: #1a1a1a;
}
.block-9 .form-control {
	outline: none !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
	font-size: 15px;
}
.block-21 .blog-img {
	display: block;
	height: 80px;
	width: 80px;
}
.block-21 .text {
	width: calc(100% - 100px);
}
.block-21 .text .heading {
	font-size: 18px;
	font-weight: 300;
}
.block-21 .text .heading a {
	color: #000;
}
.block-21 .text .heading a:hover,
.block-21 .text .heading a:active,
.block-21 .text .heading a:focus {
	color: #6927ff;
}
.block-21 .text .meta > div {
	display: inline-block;
	font-size: 12px;
	margin-right: 5px;
}
.block-21 .text .meta > div a {
	color: gray;
}
.tagcloud a {
	text-transform: uppercase;
	display: inline-block;
	padding: 4px 10px;
	margin-bottom: 7px;
	margin-right: 4px;
	border-radius: 4px;
	color: #000;
	border: 1px solid #ccc;
	font-size: 11px;
}
.tagcloud a:hover {
	border: 1px solid #000;
}
.comment-form-wrap {
	clear: both;
}
.comment-list {
	padding: 0;
	margin: 0;
}
.comment-list .children {
	padding: 50px 0 0 40px;
	margin: 0;
	float: left;
	width: 100%;
}
.comment-list li {
	padding: 0;
	margin: 0 0 30px 0;
	float: left;
	width: 100%;
	clear: both;
	list-style: none;
}
.comment-list li .vcard {
	width: 80px;
	float: left;
}
.comment-list li .vcard img {
	width: 50px;
	border-radius: 50%;
}
.comment-list li .comment-body {
	float: right;
	width: calc(100% - 80px);
}
.comment-list li .comment-body h3 {
	font-size: 18px;
	font-weight: 600;
}
.comment-list li .comment-body .meta {
	text-transform: uppercase;
	font-size: 13px;
	letter-spacing: 0.1em;
	color: #6927ff;
	margin-bottom: 15px;
}
.comment-list li .comment-body .reply {
	padding: 5px 10px;
	background: #f5f5f5;
	color: #000;
	text-transform: uppercase;
	font-size: 11px;
	letter-spacing: 0.1em;
	font-weight: 400;
	border-radius: 4px;
}
.comment-list li .comment-body .reply:hover {
	color: #fff;
	background: #000;
}
.search-form {
	background: #fafafa;
	padding: 10px;
}
.search-form .form-group {
	position: relative;
}
.search-form .form-group input {
	padding-right: 50px;
}
.search-form .icon {
	position: absolute;
	top: 50%;
	right: 20px;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}
.sidebar-box {
	margin-bottom: 30px;
	padding: 25px;
	font-size: 15px;
	width: 100%;
	background: #fff;
}
.sidebar-box *:last-child {
	margin-bottom: 0;
}
.sidebar-box h3 {
	font-size: 18px;
	margin-bottom: 20px;
	font-weight: 700;
}
.categories li {
	position: relative;
	margin-bottom: 10px;
	padding-bottom: 10px;
	border-bottom: 1px dotted #dee2e6;
	list-style: none;
}
.categories li:last-child {
	margin-bottom: 0;
	border-bottom: none;
	padding-bottom: 0;
}
.categories li a {
	display: block;
}
.categories li a span {
	position: absolute;
	right: 0;
	top: 0;
	color: #ccc;
}
.categories li.active a {
	color: #000;
	font-style: italic;
}
#ftco-loader {
	position: fixed;
	width: 96px;
	height: 96px;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	background-color: rgba(255, 255, 255, 0.9);
	-webkit-box-shadow: 0 24px 64px rgba(0, 0, 0, 0.24);
	box-shadow: 0 24px 64px rgba(0, 0, 0, 0.24);
	border-radius: 16px;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: opacity 0.2s ease-out, visibility 0s linear 0.2s;
	-o-transition: opacity 0.2s ease-out, visibility 0s linear 0.2s;
	transition: opacity 0.2s ease-out, visibility 0s linear 0.2s;
	z-index: 1000;
}
#ftco-loader.fullscreen {
	padding: 0;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
	background-color: #fff;
	border-radius: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
}
#ftco-loader.show {
	-webkit-transition: opacity 0.4s ease-out, visibility 0s linear 0s;
	-o-transition: opacity 0.4s ease-out, visibility 0s linear 0s;
	transition: opacity 0.4s ease-out, visibility 0s linear 0s;
	visibility: visible;
	opacity: 1;
}
#ftco-loader .circular {
	-webkit-animation: loader-rotate 2s linear infinite;
	animation: loader-rotate 2s linear infinite;
	position: absolute;
	left: calc(50% - 24px);
	top: calc(50% - 24px);
	display: block;
	-webkit-transform: rotate(0deg);
	-ms-transform: rotate(0deg);
	transform: rotate(0deg);
}
#ftco-loader .path {
	stroke-dasharray: 1, 200;
	stroke-dashoffset: 0;
	-webkit-animation: loader-dash 1.5s ease-in-out infinite;
	animation: loader-dash 1.5s ease-in-out infinite;
	stroke-linecap: round;
}
@keyframes loader-rotate {
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes loader-dash {
	0% {
		stroke-dasharray: 1, 200;
		stroke-dashoffset: 0;
	}
	50% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -35px;
	}
	100% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -136px;
	}
}

/* USER PROFILE */

.uProfile_card {
	border: none;

	position: relative;
	overflow: hidden;
	border-radius: 8px;
	cursor: pointer;
}

.uProfile_card:before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 4px;
	height: 100%;
	background-color: #e1bee7;
	transform: scaleY(1);
	transition: all 0.5s;
	transform-origin: bottom;
}

.uProfile_card:after {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 4px;
	height: 100%;
	background-color: #8e24aa;
	transform: scaleY(0);
	transition: all 0.5s;
	transform-origin: bottom;
}

.uProfile_card:hover::after {
	transform: scaleY(1);
}

.uProfile_fonts {
	font-size: 11px;
}

.uProfile_social-list {
	display: flex;
	list-style: none;
	justify-content: center;
	padding: 0;
}

.uProfile_social-list li {
	padding: 10px;
	color: #8e24aa;
	font-size: 19px;
}

.uProfile_buttons button:nth-child(1) {
	border: 1px solid #8e24aa !important;
	color: #8e24aa;
	height: 40px;
}

.uProfile_buttons button:nth-child(1):hover {
	border: 1px solid #8e24aa !important;
	color: #fff;
	height: 40px;
	background-color: #8e24aa;
}

.uProfile_buttons button:nth-child(2) {
	border: 1px solid #8e24aa !important;
	background-color: #8e24aa;
	color: #fff;
	height: 40px;
}

/* ERROR PAGES */

#notfound {
	position: relative;
	height: 100vh;
}

#notfound .notfound-bg {
	position: absolute;
	width: 100%;
	height: 100%;
	background-size: cover;
}

#notfound .notfound-bg:after {
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: #7e57d6;
}

#notfound .notfound {
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.notfound {
	max-width: 910px;
	width: 100%;
	line-height: 1.4;
	text-align: center;
}

.notfound .notfound-404 {
	position: relative;
	height: 200px;
}

.notfound .notfound-404 h1 {
	font-family: "Montserrat", sans-serif;
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	font-size: 220px;
	font-weight: 900;
	margin: 0px;
	color: #fff;
	text-transform: uppercase;
	letter-spacing: 10px;
}

.notfound h2 {
	font-family: "Montserrat", sans-serif;
	font-size: 22px;
	font-weight: 700;
	text-transform: uppercase;
	color: #fff;
	margin-top: 20px;
	margin-bottom: 15px;
}

.notfound .home-btn,
.notfound .contact-btn {
	font-family: "Montserrat", sans-serif;
	display: inline-block;
	font-weight: 700;
	text-decoration: none;
	background-color: transparent;
	border: 2px solid transparent;
	text-transform: uppercase;
	padding: 13px 25px;
	font-size: 18px;
	border-radius: 40px;
	margin: 7px;
	-webkit-transition: 0.2s all;
	transition: 0.2s all;
}

.notfound .home-btn:hover,
.notfound .contact-btn:hover {
	opacity: 0.9;
}

.notfound .home-btn {
	color: #7e57d6;
	background: #fff;
}

.notfound .contact-btn {
	border: 2px solid rgba(255, 255, 255, 0.9);
	color: rgba(255, 255, 255, 0.9);
}

.notfound-social {
	margin-top: 25px;
}

.notfound-social > a {
	display: inline-block;
	height: 40px;
	line-height: 40px;
	width: 40px;
	font-size: 14px;
	color: rgba(255, 255, 255, 0.9);
	margin: 0px 6px;
	-webkit-transition: 0.2s all;
	transition: 0.2s all;
}
.notfound-social > a:hover {
	color: #7e57d6;
	background-color: #fff;
	border-radius: 50%;
}

@media only screen and (max-width: 767px) {
	.notfound .notfound-404 h1 {
		font-size: 182px;
	}
}

@media only screen and (max-width: 480px) {
	.notfound .notfound-404 {
		height: 146px;
	}
	.notfound .notfound-404 h1 {
		font-size: 146px;
	}
	.notfound h2 {
		font-size: 16px;
	}
	.notfound .home-btn,
	.notfound .contact-btn {
		font-size: 14px;
	}
}

/* CUSTOM SWITCH */

.custSwitch {
	position: relative;
	display: inline-block;
	width: 60px;
	height: 34px;
  }
  
  .custSwitch input { 
	opacity: 0;
	width: 0;
	height: 0;
  }
  
  .custSlider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: .4s;
	transition: .4s;
  }
  
  .custSlider:before {
	position: absolute;
	content: "";
	height: 26px;
	width: 26px;
	left: 4px;
	bottom: 4px;
	background-color: white;
	-webkit-transition: .4s;
	transition: .4s;
  }
  
  input:checked + .custSlider {
	background-color: #2196F3;
  }
  
  input:focus + .custSlider {
	box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .custSlider:before {
	-webkit-transform: translateX(26px);
	-ms-transform: translateX(26px);
	transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .custSlider.round {
	border-radius: 34px;
	width: 100%;
	height: 100%;
  }
  
  .custSlider.round:before {
	border-radius: 50%;
  }


  /* CUSTOM SCROLLBAR */

  .cScrollBar {
    overflow-y: hidden;
    overflow-x: hidden;
    direction: ltr;
    scrollbar-color: #d4aa70 #232A36;
    scrollbar-width: thin;
    position: relative;
  }

  .cScrollBar:hover {
    overflow-y: initial;
    overflow-x: hidden;
    direction: ltr;
    scrollbar-color: #d4aa70 #232A36;
    scrollbar-width: thin;
  }
  
  .cScrollBar h2 {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }
  
  .cScrollBar p + p {
    margin-top: 1rem;
  }
  
  .cScrollBar::-webkit-scrollbar {
    width: 8px;
    padding-right: 8px;
  }
  .cScrollBar::-webkit-scrollbar:hover {
    width: 8px;
  }
  
  .cScrollBar::-webkit-scrollbar-track {
    background-color: #E2E5ED;
    border-radius: 100px;
  }
  
  .cScrollBar::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background-color: #232A36;
  }


  /* PAGE BUILDER */

  .builderbox {
    position: relative;
height:auto;
justify-content: center;
align-items: center;
}
.builderhidden {
height:30px;
-webkit-transition:height, 0.5s linear;
-moz-transition: height, 0.5s linear;
-ms-transition: height, 0.5s linear;
-o-transition: height, 0.5s linear;
transition: height, 0.5s linear;
border: dashed 2px #c3c3c3;
}
.builderbox:hover .builderhidden {
height:150px;
background-color: #FFFFFF;
-webkit-transition:height, 0.5s linear;
-moz-transition: height, 0.5s linear;
-ms-transition: height, 0.5s linear;
-o-transition: height, 0.5s linear;
transition: height, 0.5s linear;
}

.builderbottomRow {
    display: block;
    width: 100%;
    height: 100%;
}
.buildericon {
    width: 50px;
    height: 50px;
    position: absolute;
    margin: -25px 0 0 -25px;
}
.builderbottomRow:hover {
    display: block;
    width: 100%;
    height: 100%;
}

.buildercustomBtnColorTitle {
    background-color: #ffffff !important;
    color: black;
    opacity: 1;
}

.buildercustomBtnColorTitle:hover {
    background-color: #ffffff !important;
    color: black;
    opacity: 1;
}

.buildercustomBtnColor {
    background-color: #ffffff !important;
    color: black;
}

.buildercustomBtnColor:hover {
    background-color: #168dff !important;
    color: #ffffff;
}

.buildercustomBtnColorDanger {
    background-color: #ffffff !important;
    color: black;
}

.buildercustomBtnColorDanger:hover {
    background-color: #f34d89 !important;
    color: #ffffff;
}

.builderdndcard {
    position: relative;
    display: inline-block;
    padding: 0.5em 2em;
    overflow: hidden;
}

.buildertopright {
    position: absolute;
    display: none;
    top: 8px;
    right: 16px;
    font-size: 18px;
    border: none !important;
}

.buildertopleft {
    position: absolute;
    display: none;
    top: 8px;
    left: 16px;
    font-size: 18px;
    border: none !important;
}

.builderbottomright {
    position: absolute;
    display: none;
    top: 50px;
    right: 16px;
    font-size: 18px;
    border: none !important;
}

.builderbottomAdd{
    position: absolute;
    display: none;
    bottom: -2px;
    right: 50%;
    font-size: 18px;
    border: none !important;
}

.builderdndcard:hover .builderbottomAdd{
    position: absolute;
    display: block;
    bottom: -2px;
    right: 50%;
    font-size: 18px;
    border: none !important;
}

.builderdndcard:hover {
    position: relative;
    display: inline-block;
    padding: 0.5em 2em;
    overflow: hidden;
    background-color: #ffffff;
}

.builderdndcard:hover .builderbottomright {
    display: block;
    animation: fade_in_show 0.5s;
}

.builderdndcard:not(:hover) .builderbottomright {
    display: none;
    animation: fade_out_show 0.5s;
}

.builderdndcard:hover .buildertopright {
    display: block;
    animation: fade_in_show 0.5s;
}

.builderdndcard:not(:hover) .buildertopright {
    display: none;
    animation: fade_out_show 0.5s;
}

.builderdndcard:hover .buildertopleft {
    display: block;
    animation: fade_in_show 0.5s;
}

.builderdndcard:not(:hover) .buildertopleft {
    display: none;
    animation: fade_out_show 0.5s;
}

@keyframes fade_in_show {
    0% {
        opacity: 0;
        transform: scale(0);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes fade_out_show {
    0% {
        opacity: 0;
        transform: scale(0);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.builderdndcard:before,
.builderdndcard:after {
    content: "";
    position: absolute;
    left: 0;
    height: 2px;
    width: 100%;
}
.builderdndcard:before {
    top: 0;
}
.builderdndcard:after {
    bottom: 0;
}
.builderdndcard:hover:before,
.builderdndcard:hover:after {
    transform: translate3d(0, 0, 0);
}
.builderdndcard:hover > span:before,
.builderdndcard:hover > span:after {
    transform: translate3d(0, 0, 0);
}
.builderdndcard > span:before,
.builderdndcard > span:after {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    width: 2px;
}
.builderdndcard > span:before {
    left: 0;
}
.builderdndcard > span:after {
    right: 0;
}
.builderdndcard:before,
.builderdndcard:after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 9;
    height: 2px;
    width: 100%;
    background-color: #2150bf;
}
.builderdndcard:before {
    top: 0;
    transform: translate3d(-105%, 0, 0);
    transition: transform 0.8s cubic-bezier(0.4, 0, 0.2, 1);
}
.builderdndcard:after {
    bottom: 0;
    transform: translate3d(105%, 0, 0);
    transition: transform 0.8s cubic-bezier(0.4, 0, 0.2, 1);
}
.builderdndcard > span:before,
.builderdndcard > span:after {
    content: "";
    position: absolute;
    top: 0;
    z-index: 9;
    height: 100%;
    width: 2px;
    background-color: #2150bf;
}
.builderdndcard > span:before {
    left: 0;
    transform: translate3d(0, 105%, 0);
    transition: transform 0.8s cubic-bezier(0.4, 0, 0.2, 1);
}
.builderdndcard > span:after {
    right: 0;
    transform: translate3d(0, -105%, 0);
    transition: transform 0.8s cubic-bezier(0.4, 0, 0.2, 1);
}
.buildertopRow {
    height: 10px;
}
.buildertopRow.active {
    height: 100px;
}