.lang {
	position: fixed;
	left: 1%;
  font-weight: bold;
	background-color: var(--primary);
	border-radius: 5px !important;
	z-index: 999;
}

#google_translate_element {
	padding-top: 0.3rem;
	padding-bottom: 0.2rem;
	padding-right: 0.5rem;
	padding-left: 0.5rem;
}

.goog-te-gadget {
}

.goog-te-gadget-icon {
  background-image: url(https://placehold.it/32) !important;
}

.goog-te-gadget-simple {
	background-color: rgba(255, 255, 255, 0.2) !important;
	display: inline-block;
	cursor: pointer;
	zoom: 1;
	padding-left: 0px !important;
}

.goog-te-menu2 {
	background-color: #000000 !important;
	text-decoration: none;
	border: 1px solid #f00 !important;
	overflow: hidden;
	padding: 4px;
}

.goog-te-menu-value {
	color: #fff !important;
	border: 0px !important;
	background: none !important;
	padding-left: 0px !important;
	padding-right: 0px !important;
}
.goog-te-menu-value:before {
	border: 0px !important;
	padding-left: 0px !important;
}

.goog-te-gadget-simple {
	border: 0px !important;
	background: none !important;
}

.goog-te-menu-value span:nth-child(5) {
	border: 0px !important;
	display: none;
}

.goog-te-menu-value span:nth-child(3) {
	border: 0px !important;
}
.goog-te-menu-value span:nth-child(3):after {
	border: 0px !important;
}

.goog-te-gadget-icon {
	background-position: 0px 0px;
	display: none;
}

.goog-te-banner-frame.skiptranslate {
	display: none !important;
}
